@use '../utilities/variables' as *;

.ngx-charts-outer {
  width: 100% !important;
  height: 100% !important;
  display: flex;

  .ngx-charts {
    // TODO: Remove @supports when Firefox will finally support :has selector
    @supports selector(:has(*)) {
      &:not(
          :has(g.pie-chart, g.gauge, g.linear-gauge, g.tree-map, g.number-card)
        ) {
        padding: 0 0 $bbraun-spacing-m $bbraun-spacing-sm;
      }
    }

    @supports not selector(:has(*)) {
      padding: 0 0 $bbraun-spacing-m $bbraun-spacing-sm;
    }

    path.bar.active,
    path.bar:hover,
    rect.cell:hover,
    g.cell:hover,
    path.arc.active,
    path.arc:hover {
      opacity: 0.64;
    }

    path.reference-area {
      fill-opacity: 0.08;
      fill: $bbraun-non-clickable;
    }

    text.refline-label {
      font-size: $bbraun-font-size-small;
      fill: $bbraun-clickable;
    }
  }

  ngx-charts-legend.chart-legend {
    display: flex;
    align-items: center;

    header.legend-title {
      margin-left: 0;
      margin-bottom: $bbraun-spacing-s;

      .legend-title-text {
        font-size: $bbraun-font-size-big;
        font-weight: bold;
      }
    }

    ul.legend-labels {
      line-height: 0.5;
      border-radius: $bbraun-border-radius;
      background: $bbraun-background;

      overflow: hidden;
      word-break: break-all;

      li.legend-label {
        margin: 0;
        padding: $bbraun-spacing-xs;
        cursor: auto;

        &:has(.active),
        &:hover {
          // #cbcbcb - merged $bbraun-background + ($bbraun-non-clickable + $bbraun-opacity-hover)
          background: #cbcbcb !important;
          border-radius: $bbraun-border-radius;
          color: $bbraun-non-clickable !important;
        }

        .legend-label-color {
          margin-right: $bbraun-spacing-s;
          width: $bbraun-spacing-sm;
          height: $bbraun-spacing-sm;
        }

        .legend-label-text {
          color: $bbraun-non-clickable;
          font-size: $bbraun-font-size-medium;
        }
      }
    }
  }

  [ngx-charts-axis-label] text {
    font-size: 20px;
  }

  .x.axis [ngx-charts-axis-label] text {
    transform: translateY($bbraun-spacing-m);
    margin-top: $bbraun-spacing-m;
  }

  .y.axis [ngx-charts-axis-label] text {
    margin-right: $bbraun-spacing-m;
    transform: rotate(270deg) translateY(-$bbraun-spacing-sm);
  }

  g.tick text {
    font-size: $bbraun-font-size-medium !important;
  }
}

ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
  word-wrap: break-word;
  box-sizing: border-box;
  background-color: $bbraun-non-clickable !important;
  border-radius: $bbraun-border-radius !important;
  padding: $bbraun-spacing-xs $bbraun-spacing-sm !important;
  line-height: $bbraun-line-height !important;
  max-width: 177px !important;
  text-align: left !important;

  .tooltip-caret {
    &.position-top,
    &.position-right,
    &.position-bottom,
    &.position-left {
      display: none;
    }
  }

  .area-tooltip-container {
    padding: 0;
  }

  .tooltip-label,
  .tooltip-val,
  .tooltip-item {
    font-size: $bbraun-font-size-medium !important;
  }

  .tooltip-label {
    font-weight: bold;
    padding: 0 0 5px;
  }

  .tooltip-val {
    font-weight: normal;
    padding: 0;
  }

  .tooltip-item {
    &:not(:last-of-type) {
      padding: 0 0 2px;
    }
    &:last-of-type {
      padding: 0;
    }
  }
}

ngx-charts-advanced-pie-chart {
  .advanced-pie.chart + .advanced-pie-legend-wrapper {
    .advanced-pie-legend {
      .total-value {
        line-height: $bbraun-spacing-l;
      }

      .total-label {
        margin-bottom: $bbraun-spacing-ml;
      }

      .legend-items-container .legend-items .legend-item .item-color {
        width: 0;
        height: 98px;
        border-left: $bbraun-spacing-xs solid;
        margin-right: $bbraun-spacing-sm;
      }
    }
  }

  div.advanced-pie-legend {
    transform: translateY(-50%) translateX($bbraun-spacing-l);
    color: $bbraun-non-clickable;

    .legend-items-container .legend-items .legend-item {
      margin-right: 60px;

      div.item-label,
      div.item-percent {
        opacity: 1;
        margin-top: 6px;
      }

      div.item-label {
        font-size: $bbraun-font-size-big;
      }
    }
  }
}

ngx-charts-pie-chart {
  .pie-label.animation {
    font-size: $bbraun-font-size-medium;
  }
}

ngx-charts-pie-grid {
  .pie-grid {
    .arc1 .arc {
      opacity: 1;
      fill: $bbraun-disabled;
    }

    text.label.percent-label {
      font-size: 32px;
      font-weight: bold;
      transform: translateY(10px);
    }

    text.label:not(.percent-label) {
      font-size: $bbraun-font-size-big;
      transform: translateY(10px);
    }

    text.label:not(.percent-label):last-of-type {
      font-weight: bold;
    }
  }
}

ngx-charts-gauge .gauge.chart {
  & > text {
    transform: none;
    font-size: 18px;
  }

  .gauge-tick {
    &.gauge-tick-large {
      text {
        font-size: 20px;
        fill: $bbraun-non-clickable;
        font-weight: normal;
      }
    }

    text {
      font-size: 20px;
      font-weight: normal;
    }

    path {
      stroke: $bbraun-non-clickable;
    }
  }
}

ngx-charts-heat-map .heat-map.chart {
  rect.cell {
    rx: 8px;
  }
}

.linear-gauge.chart {
  text.value,
  text.units {
    transform: scale(1, 1);
    font-size: 32px;
  }

  text.value {
    color: $bbraun-non-clickable;
  }

  text.units {
    fill: $bbraun-non-clickable;
  }

  .background-bar {
    path.bar {
      fill: $bbraun-background;
    }
  }
}

ngx-charts-tree-map .tree-map.chart {
  .treemap-label {
    font-size: 20px;
    line-height: 18px;
    font-weight: $bbraun-font-weight-bold;
    word-break: break-all;
    overflow: hidden;
    color: $bbraun-non-clickable;
  }

  .treemap-val {
    padding-top: 0;
    font-size: 20px;
    line-height: 20px;
    color: $bbraun-non-clickable;
    font-weight: $bbraun-font-weight-normal;
  }
}

ngx-charts-number-card .number-card.chart {
  rect {
    rx: 8px;
    ry: 8px;
  }

  .value-text {
    font-size: 24px !important;
    fill: $bbraun-non-clickable !important;
    transform: translateY(2px);
  }

  .trimmed-label {
    transform: translateY(-$bbraun-spacing-xs);

    &,
    p {
      font-size: $bbraun-font-size-medium !important;
      line-height: $bbraun-font-size-medium !important;
      fill: $bbraun-non-clickable !important;
    }
  }

  path.card-band {
    clip-path: inset(2px 0 0 0 round 0 0 $bbraun-spacing-xs $bbraun-spacing-xs);
  }
}

ngx-charts-heat-map {
  .chart-legend {
    .scale-legend-wrap {
      width: $bbraun-spacing-ml;
      margin: $bbraun-spacing-xs auto;
    }

    .scale-legend-label {
      font-size: $bbraun-font-size-medium;
      align-self: center;
    }
  }
}

ngx-charts-box-chart .box-chart.chart + g {
  .bar-line {
    stroke: $bbraun-non-clickable;
    fill: $bbraun-non-clickable;
  }
}

// TODO: Remove @supports when Firefox will finally support :has selector
@supports not selector(:has(*)) {
  ngx-charts-number-card,
  ngx-charts-tree-map,
  ngx-charts-linear-gauge,
  ngx-charts-gauge,
  ngx-charts-pie-grid,
  ngx-charts-pie-chart,
  ngx-charts-advanced-pie-chart {
    .ngx-charts-outer .ngx-charts {
      padding: 0;
    }
  }
}

// GLOBAL CLASSES
.cx-charts-clickable-legend {
  ngx-charts-legend.chart-legend ul.legend-labels {
    li.legend-label {
      cursor: pointer;
    }

    .legend-label:has(.active) {
      color: $bbraun-clickable !important;
    }

    .legend-label-text {
      color: $bbraun-clickable !important;
    }
  }
}

.cx-charts-legend-below {
  .ngx-charts-outer {
    display: block;

    ngx-charts-legend.chart-legend {
      display: inline-flex;
    }
  }
}
