.mat-drawer-backdrop
  position: fixed !important
  z-index: 1010 !important

cx-sidebar
  z-index: 1020 !important

cx-sidebar-container
  position: unset !important

cx-sidebar-header
  padding-top: 29px !important
  line-height: 22px !important

// added because of the sidebar z-index override - to work with tooltips and popups
.cdk-overlay-container
  z-index: 2000
