/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

// CxSidebar Component
.cx-sidebar-portal-container {
  height: 100%;

  & > * {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
