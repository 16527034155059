/* stylelint-disable annotation-no-unknown */

@use 'sass:map';
@use 'sass:list';

/// CSS classes generator based on definitions map
/// @group mixins
/// @param {Map} $definitions - map with a specific structure. See below
/// (
///   "key": (
///     (optional) "class": "The name of the class.
///                          If not provided, key will be used.",
///
///     (optional) "property": "CSS property that will be used
///                             in order to create class.
///                             If not provided, key will be used.",
///
///     (required) "values": ...,
///
///     (optional) "important": "boolean value which specifies
///                              if the clss is '!important'. Default false"
///   )
/// )
///
/// Values can be either map or list.
/// 1. When a list, then the class name will have the used value in it:
///     "values": a b
///   result:
///     key-a {property:a} key-b {property:b}
///
/// 2. When a map, then the class name will have the map key,
///                         and property will get the value:
///     "values": ("a": a%, "b": b%)
///   result:
///     key-a {property:a%} key-b {property:b%}
///
/// @param {Boolean} $are-all-important - whether the every css property
///                                       should have '!important' at the end
/// @output css classes
@mixin generate-classes($definitions, $are-all-important: false) {
  @each $key, $definition in $definitions {
    $property: map.get($definition, 'property');

    @if not $property {
      $property: $key;
    }

    $class-name: map.get($definition, 'class');

    @if not $class-name {
      $class-name: $key;
    }

    $values: map.get($definition, 'values');
    $is-important: map.get($definition, 'important');

    @if type-of(nth($values, 1)) != 'list' {
      $values: list.zip($values, $values);
    }

    @each $key, $value in $values {
      .#{$class-name}-#{'' + $key} {
        #{$property}: $value
          if(
            $is-important,
            !important,
            if($are-all-important, !important, null)
          );
      }
    }
  }
}
