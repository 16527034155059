/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */

// Tooltip
.mdc-tooltip__surface {
  box-sizing: border-box;
  background-color: $bbraun-non-clickable !important;
  border-radius: $bbraun-border-radius !important;
  font-size: $bbraun-font-size-medium !important;
  padding: $bbraun-spacing-xs $bbraun-spacing-sm !important;
  line-height: $bbraun-line-height !important;
  max-width: 177px !important;
  max-height: 80vh !important;
  text-align: left !important;
}

.mat-mdc-tooltip-panel-below .mdc-tooltip::before {
  top: -10px !important;
}
