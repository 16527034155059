/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */

$ripple-size: 36px;

// Shared among selection controls
.mat-mdc-checkbox,
.mat-mdc-radio-button,
.mat-mdc-slide-toggle {
  label {
    font-size: $bbraun-font-size-big;
    color: $bbraun-non-clickable;
  }

  // Remove @supports when Firefox will finally support :has selector
  @supports selector(:has(*)) {
    &:has([disabled]) label {
      color: $bbraun-disabled !important;
    }
  }
}

// Remove this code when Firefox will finally support :has selector
@supports not selector(:has(*)) {
  .mat-mdc-checkbox.mdc-checkbox--disabled,
  .mat-mdc-radio-button .mdc-radio--disabled +,
  .mat-mdc-slide-toggle .mdc-switch--disabled + {
    & label {
      color: $bbraun-disabled;
    }
  }
}

.mat-mdc-checkbox-touch-target,
.mdc-checkbox__native-control,
.mat-mdc-radio-touch-target,
.mdc-radio__native-control,
.mdc-radio__background::before,
.mdc-switch__ripple {
  width: $ripple-size !important;
  height: $ripple-size !important;
}
