body
  min-width: 1280px /* As per non-functional requirements */

.cx-header
  position: fixed !important
cx-navigation-drawer-container
  margin-top: calc(76px + 32px) !important

@media screen and (max-width: 1280px)
  .main-navigation-drawer-container
    cx-navigation-drawer-container
      margin-left: 0 !important
    .cx-navigation-drawer
      margin-left: -256px !important
