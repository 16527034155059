// radio button to be on start of the column with 2 rows text
.workshop-delivery-address-item
  .mdc-form-field
    align-items: flex-start!important

  .mdc-radio
    padding-top: 5px!important

  .mdc-label
    margin-top: 1px!important

.overview-dropdown-like-option
  mat-checkbox
    .mdc-checkbox
      .mdc-checkbox__ripple
        background-color: transparent !important

.mat-mdc-menu-panel.notification-menu-container
  width: 350px
  max-width: 350px

.mat-mdc-menu-submenu-icon
  display: none

// https://github.com/angular/components/issues/26233
// temp fix for the textarea directive
app-proforma-details
  textarea.mat-mdc-input-element
    font: inherit

.no-margins-form-field
  div.mat-mdc-text-field-wrapper
    margin: 0
