/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */
@use '../../utilities/variables' as *;

// CxCard Component
.cx-card {
  .cx-no-image-icon:not(.cx-thumbnail-image) {
    width: 100px !important;
    height: 100px !important;
    font-size: 100px !important;
  }

  .mat-mdc-checkbox,
  .mat-mdc-radio-button {
    margin-top: 0;
  }

  &:not(.cx-card-disabled) {
    button,
    .cx-card-select-button,
    .mat-mdc-checkbox-touch-target,
    .mat-mdc-radio-touch-target {
      z-index: 2 !important;
    }
  }

  .no-ripple-control {
    .mdc-radio__native-control ~ .mdc-radio__background::before,
    .mdc-checkbox__native-control ~ .mdc-checkbox__ripple {
      opacity: 0 !important;
    }
  }

  .mat-mdc-radio-button label {
    padding: 0;
  }

  .cx-card-wrapper.small .cx-card-content > div:first-child,
  .cx-card-wrapper.cx-card-small .cx-card-content > div:first-child {
    margin-top: $bbraun-spacing-sm;
  }

  .cx-card-wrapper.regular .cx-card-content > div:first-child,
  .cx-card-wrapper.cx-card-regular .cx-card-content > div:first-child {
    margin-top: $bbraun-spacing-m;
  }

  .cx-card-wrapper.large .cx-card-content > div:first-child,
  .cx-card-wrapper.cx-card-large .cx-card-content > div:first-child {
    margin-top: $bbraun-spacing-ml;
  }

  .cx-card-aligned-mat-icon-button .mat-mdc-icon-button {
    bottom: -2px;
  }
}

.cx-truncate-title .cx-card-title,
.cx-truncate-subtitle .cx-card-subtitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

cx-card.cx-card-clickable .cx-card,
cx-card .cx-card.cx-card-selected,
cx-card .cx-card.cx-card-disabled {
  &::before {
    content: '';
    height: 100%;
    width: 100%;
    z-index: 2;
    display: none;
    position: absolute;
    border-radius: $bbraun-border-radius;
    top: 0;
    left: 0;
  }
}

cx-card.cx-card-clickable .cx-card:not(.cx-card-selected):active,
.cx-card.cx-card-disabled {
  box-shadow: $bbraun-shadow-s !important;
}

cx-card.cx-card-clickable{
  cursor: auto !important;

  .cx-card:not(.cx-card-disabled) {
    cursor: pointer;

    &:not(.cx-card-selected):hover::before {
      background: rgba($bbraun-clickable, $bbraun-opacity-hover);
      display: block;
    }

    &:not(.cx-card-selected):active::before {
      background: rgba($bbraun-clickable, $bbraun-opacity-active);
      display: block;
    }
  }
}
