/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

// CxMenu Component
cx-menu-item mat-icon.x-small-icon {
  margin: 0 $bbraun-spacing-xs 0 $bbraun-spacing-sm !important;
  position: absolute;
  right: 0;
  align-self: center;
}

.cx-menu-button {
  // First disable the ripple effect on the menu trigger
  // button and add this class to get the needed behavior
  & > .mat-mdc-button-persistent-ripple {
    display: none !important;
  }

  &.mdc-button[disabled]:hover {
    background: transparent !important;
  }

  &:hover {
    background: rgba($bbraun-clickable, $bbraun-opacity-hover) !important;
  }
}

button.cx-menu-button-opened,
div.cx-menu-button-opened button {
  background: rgba($bbraun-clickable, $bbraun-opacity-active) !important;
}
