/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

// CxBadge Component
span.cx-badge {
    font-size: $bbraun-font-size-medium;
    font-weight: normal;
    padding: 2px $bbraun-spacing-xs;
    border-radius: $bbraun-border-radius;
    display: inline-block;

    &-primary {
        @extend .cx-badge;
        color: $bbraun-non-clickable;

        &-purple {
            @extend .cx-badge-primary;
            background: $bbraun-purple-30;
        }

        &-green {
            @extend .cx-badge-primary;
            background: $bbraun-green-20;
        }

        &-blue {
            @extend .cx-badge-primary;
            background: $bbraun-blue-20;
        }

        &-red {
            @extend .cx-badge-primary;
            background: $bbraun-red-30;
        }

        &-yellow {
            @extend .cx-badge-primary;
            background: $bbraun-orange-30;
        }

        &-light-green {
            @extend .cx-badge-primary;
            background: $bbraun-light-green-30;
        }

        &-sand {
            @extend .cx-badge-primary;
            background: $bbraun-sand-30;
        }

        &-gray {
            @extend .cx-badge-primary;
            background: $bbraun-gray-30;
        }
    }

    &-secondary {
        @extend .cx-badge;
        color: $bbraun-space;

        &-purple {
            @extend .cx-badge-secondary;
            background: $bbraun-accent;
        }

        &-green {
            @extend .cx-badge-secondary;
            background: $bbraun-active;
        }

        &-blue {
            @extend .cx-badge-secondary;
            background: $bbraun-links;
        }

        &-red {
            @extend .cx-badge-secondary;
            background: $bbraun-error;
        }

        &-yellow {
            @extend .cx-badge-secondary;
            background: $bbraun-warning;
            color: $bbraun-non-clickable;
        }

        &-light-green {
            @extend .cx-badge-secondary;
            background: $bbraun-light-green-60;
        }

        &-sand {
            @extend .cx-badge-secondary;
            background: $bbraun-sand-70;
        }

        &-gray {
            @extend .cx-badge-secondary;
            background: $bbraun-non-clickable;
        }
    }
}

span.cx-badge-notification {
        border-radius: 50px;
        background-color: $bbraun-accent;
        color: $bbraun-space;

        &-sm {
            @extend .cx-badge-notification;
            height: 10px;
            width: 10px;

            &-icon {
                @extend .cx-badge-notification-sm;
                border: 1px solid $bbraun-space;
                position: absolute;
                top: 0;
                left: calc(24px / 2);
                font-size: xx-small;
            }
        }

        &-md {
            @extend .cx-badge-notification;
            padding: 0 4.5px;
            font-size: 9px;
            line-height: 14px;
            height: 14px;

            &-icon {
                @extend .cx-badge-notification-md;
                border: 1px solid $bbraun-space;
                position: absolute;
                top: 0;
                left: calc(32px / 2);
            }
        }

        &-lg {
            @extend .cx-badge-notification;
            padding: 0 7px;
            font-size: $bbraun-font-size-medium;
            line-height: 22px;
            height: 22px;

            &-icon {
                @extend .cx-badge-notification-lg;
                border: 1px solid $bbraun-space;
                position: absolute;
                top: 0;
                left: calc(48px / 2);
            }
        }
}

div.cx-badge {
    position: relative;
    display: inline-flex;
}

button.mdc-icon-button {
    .cx-badge-notification-sm-icon {
      font-weight: normal;
      left: calc(36px / 2) !important;
      top: calc((36px - 24px) / 2) !important;
    }

    .cx-badge-notification-md-icon {
      font-weight: normal;
      left: calc(40px / 2) !important;
      top: calc((40px - 32px) / 2) !important;
    }

    .cx-badge-notification-lg-icon {
      font-weight: normal;
      left: calc(60px / 2) !important;
      top: calc((60px - 48px) / 2) !important;
    }
  }


