/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */
@use '../../utilities/variables' as *;
@use 'sass:color';

// Table
.mat-mdc-row:hover {
  background: rgba($bbraun-clickable, 0.04) !important;
  transition: background-color 0.2s ease;
}

.mat-mdc-cell {
  font-size: $bbraun-font-size-medium;
  word-break: break-word;
}

.mat-mdc-cell:focus {
  outline: none;
}

//Cx Table-with-pagination
cx-table-with-pagination,
cx-table-with-lazy-loading {

  .mat-mdc-checkbox.mat-primary {
    margin-top: unset; // removed global style for the checkbox
  }

  .mat-form-field-appearance-outline {
    .mat-mdc-text-field-wrapper {
      margin-top: 0;
    }
  }

  .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label {
    margin-right: 16px;
    align-self: stretch;
    display: flex;
    align-items: center;
    &:after {
      content: '';
      width: 0;
      height: 100%;
      border-left: 1px solid $bbraun-gray-30;
      margin-left: 24px;
    }
  }

  mat-table {
    border-radius: $bbraun-border-radius;

    &:not(.cx-visible-table-border) {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    }
  }

  mat-cell > * {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  mat-row mat-cell.mat-mdc-cell,
  mat-header-cell.mat-mdc-header-cell {
    padding: 0 12px !important;
  }

  mat-table.cx-no-main-checkbox-column {
    mat-header-cell:first-of-type,
    mat-cell:first-of-type {
      margin-left: 8px;
    }
  }

  mat-table.cx-no-optional-columns-column {
    mat-header-cell:last-of-type,
    mat-cell:last-of-type {
      margin-right: 8px;
    }
  }

  mat-table.cx-one-row-cell mat-row,
  mat-table.cx-two-rows-cell mat-row {
    max-height: 56px;
  }

  mat-table.cx-three-rows-cell mat-row,
  mat-table.cx-more-rows-cell mat-row {
    max-height: 112px;
  }

  mat-table.cx-more-rows-cell mat-cell {
    padding: 12px !important;
  }

  mat-table.cx-one-row-cell mat-cell > * {
    -webkit-line-clamp: 1;
  }

  mat-table.cx-two-rows-cell mat-cell > * {
    -webkit-line-clamp: 2;
  }

  mat-table.cx-three-rows-cell mat-cell > * {
    -webkit-line-clamp: 3;
  }

  mat-row.mat-mdc-row,
  mat-header-row.mat-mdc-header-row {
    border-bottom: 1px solid $bbraun-gray-30;

    .mat-mdc-cell,
    .mat-mdc-header-cell {
      border: unset;
      background: unset;
    }
  }

  mat-row.mat-mdc-row {
    &.cx-selected-row,
    &.cx-highlighted-row {
      background-color: rgba($bbraun-active, 0.08) !important;
    }

    &.cx-highlighted-row {
      border: 1px solid $bbraun-active;

      &.cx-highlighted-row-remove-bottom-border {
        border-bottom: unset;
      }
    }

    &.cx-highlighted-row.cx-selected-row {
      background-color: rgba($bbraun-active, 0.16) !important;
    }
  }

  mat-row.mat-mdc-row:not(.cx-selected-row):not(.cx-highlighted-row):hover {
    background-color: $bbraun-space !important;
  }

  mat-row.mat-mdc-row.cx-enable-row-hover:not(.cx-selected-row):not(.cx-highlighted-row):hover{
    background-color: rgba($bbraun-gray-80, $bbraun-opacity-hover) !important;
  }

  mat-header-cell.mat-mdc-header-cell {

    &.cx-no-sort-column {
      .cx-title-text {
        max-width: calc(100% - 36px - 6px); //removing filter with margin
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
      }
   }

    &.cx-no-sort-column.cx-no-sort-column-without-filter {
      .cx-title-text {
        max-width: 100%;
      }
    }

    .mat-sort-header-content {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-all;
      text-align: left;
    }

    &[mat-sort-header]:not(:active):hover,
    &[mat-sort-header]:active {
      position: relative;
      background-color: unset !important; // due to important in old table styles
      &::after {
        position: absolute;
        content: '';
        min-height: 48px;
        max-height: 48px;
        margin-top: 4px !important;  // due to important in old table styles
        margin-bottom: 4px !important; // due to important in old table styles
        border-radius: $bbraun-border-radius;
        opacity: 0;
        display: flex;
        width: 100%;
        left: 0;
      }

    }

    &[mat-sort-header]:not(:active):hover {
      &::after {
        opacity: 1;
        background-color: rgba(red($bbraun-gray-120), green($bbraun-gray-120), blue($bbraun-gray-120), $bbraun-opacity-hover);
      }
    }

    // Pressed state
    &[mat-sort-header]:active {
      &::after {
        opacity: 1;
        background-color: rgba(color.channel($bbraun-active, 'red', $space: rgb),
                               color.channel($bbraun-active, 'green', $space: rgb),
                               color.channel($bbraun-active, 'blue', $space: rgb),
                               $bbraun-opacity-active);
      }
      cx-table-filter button.cx-table-filter-button {
        &:hover {
          background-color: rgba(red($bbraun-active), green($bbraun-active), blue($bbraun-active), $bbraun-opacity-active);
        }
        mat-icon {
          fill: $bbraun-active;
        }
      }
      .mat-sort-header-arrow {
        color: $bbraun-active;
      }
      color: $bbraun-active;
    }
  }

  mat-table.cx-one-row-header-cell mat-header-cell.mat-mdc-header-cell {
    &.cx-no-sort-column .cx-title-text,
    .mat-sort-header-content{
      -webkit-line-clamp: 1;
    }
  }

  mat-table.cx-two-rows-header-cell mat-header-cell.mat-mdc-header-cell {
    &.cx-no-sort-column .cx-title-text,
    .mat-sort-header-content {
      -webkit-line-clamp: 2;
    }
  }

  mat-header-cell[aria-sort="ascending"].mat-mdc-header-cell:hover,
  mat-header-cell[aria-sort="descending"].mat-mdc-header-cell:hover {
    background-color: rgba(red($bbraun-green-60), green($bbraun-green-60), blue($bbraun-green-60), $bbraun-opacity-hover);
  }

  mat-header-row.mat-mdc-header-row {
    background-color: $bbraun-gray-20;
    border-top-left-radius: $bbraun-border-radius;
    border-top-right-radius: $bbraun-border-radius;

    &.cx-full-header-border {
      border: 1px solid $bbraun-gray-30;
    }
  }

  // Handling of custom sort icon
  mat-header-cell.mat-mdc-header-cell {
    $sort-icon: 'arrow_upward';
    font-size: $bbraun-font-size-big;
    font-weight: $bbraun-font-weight-semi-bold;

    cx-table-filter {
      color: $bbraun-gray-80;
      z-index: 10;
    }

    .mat-sort-header-arrow {
      opacity: 1 !important;
      transform: none !important;
      visibility: visible;
      font-size: 24px;
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      color: $bbraun-gray-80;
      &::after {
        display: flex;
        align-items: center;
        font-family: 'Material Icons', sans-serif;
        -webkit-font-feature-settings: 'liga';
        content: $sort-icon;
        transition: transform 0.5s ease;
      }
    }

    // My custom ascending arrow
    &[aria-sort="ascending"] {
      $sort-icon: 'arrow_upward';
      .mat-sort-header-arrow {
        &::after {
          content: $sort-icon;
        }
      }
    }

    // My custom descending arrow
    &[aria-sort="descending"] {
      $sort-icon: 'arrow_upward';
      .mat-sort-header-arrow {
        &::after {
          content: $sort-icon;
          transform: rotate(180deg);
        }
      }
    }

    & > div.mat-sort-header-container.mat-sort-header-sorted {
      color: $bbraun-active;

      & > .mat-sort-header-arrow {
        color: $bbraun-active;
      }
    }

    .mat-sort-header-stem,
    .mat-sort-header-pointer-left,
    .mat-sort-header-pointer-right,
    .mat-sort-header-pointer-middle {
      display: none;
    }

    .mat-sort-header-arrow {
      display: flex;
      align-items: center;

      & > svg {
        display: none;
      }
    }
  }

  mat-header-cell:first-of-type {
    border-top-left-radius: $bbraun-border-radius;
  }

  mat-header-cell:last-of-type {
    border-top-right-radius: $bbraun-border-radius;
  }

  mat-paginator {
    &.cx-visible-table-border {
      border-bottom-left-radius: $bbraun-border-radius;
      border-bottom-right-radius: $bbraun-border-radius;
    }

    .mat-mdc-paginator-page-size-label,
    .mat-mdc-paginator-range-label,
    mat-select .mat-mdc-select-value-text {
      font-size: 16px;
    }
  }

  mat-table.cx-visible-table-border {

    mat-header-row {
      border: 1px solid $bbraun-gray-30;
    }

    mat-row{
      border-left: 1px solid $bbraun-gray-30;
      border-right: 1px solid $bbraun-gray-30;
    }

    mat-footer-row {
      border: 1px solid $bbraun-gray-30;
    }

    mat-footer-row,
    mat-footer-cell,
    &.no-paginator mat-row:last-of-type mat-cell:first-of-type {
      border-bottom-left-radius: $bbraun-border-radius;
    }

    mat-footer-row,
    mat-footer-cell,
    &.no-paginator mat-row:last-of-type mat-cell:last-of-type {
      border-bottom-right-radius: $bbraun-border-radius;
    }

    &.no-paginator mat-row:last-of-type {
      border-bottom-left-radius: $bbraun-border-radius;
      border-bottom-right-radius: $bbraun-border-radius;
    }
  }

  mat-paginator.cx-visible-table-border {
    border: 1px solid $bbraun-gray-30;
    border-top: unset;
  }

  mat-footer-row.mat-mdc-footer-row.cx-table-footer-row,
  mat-footer-cell.mat-mdc-footer-cell.cx-no-results-footer-row-container  {
    height: auto;
    max-height: 240px;
  }

  mat-header-cell.cx-right-aligned-cell,
  mat-cell.cx-right-aligned-cell {
    justify-content: flex-end;
  }

  mat-header-cell.cx-left-aligned-cell,
  mat-cell.cx-left-aligned-cell {
    justify-content: flex-start;
  }

  mat-cell.cx-center-aligned-cell {
    justify-content: center;
  }

  mat-cell.cx-optional-column {
    button:not(:last-of-type) {
      margin-right: 6px;
    }
  }

}

cx-table-toolbar-buttons {
  display: flex;
  align-items: center;
  & > *:not(:last-of-type) {
    margin-right: 16px;
  }
}

// CxTableFilter
// CxTableMenu

$dropdown-max-height: 394px;

.cx-dropdown-menu-container,
.cx-filter-container {
  background-color: $bbraun-space;
  box-shadow: $bbraun-shadow-l;
  border-radius: $bbraun-border-radius;
  outline: 1px solid $bbraun-gray-30;
  overflow: hidden;
  margin-top: 4px;
}

.cx-filter-container {
  position: relative;
}

.cx-dropdown-menu-container,
.cx-filter-container:not(.calendar-container) {
  min-width: 440px;
  max-width: 440px;
  max-height: $dropdown-max-height;
}

.cx-filter-container.calendar-container {
  min-width: 296px;
  max-height: 394px;
}

.cx-scrollable-area,
.cx-table-menu-drag-zone {
  overflow: auto;
  max-height: calc($dropdown-max-height - (2 * (56px + 1px) ));
  &.cx-modified-scrollable-height {
    max-height: calc($dropdown-max-height - (2 * (56px + 1px)) - (44px + (2 * 8px))); // exclude the search bar
  }
}

cx-table-menu-item,
cx-table-filter-item {
  .mat-mdc-checkbox.mat-primary {
    margin-top: unset; // removed global style for the checkbox
  }

  .table-menu-item-container,
  .table-filter-item-container {
    height: 56px;
    flex-grow: 1;
  }

  .menu-item-selected,
  .filter-item-selected {
    background-color: rgba($bbraun-active, 0.08) !important;
  }

  .last-border-radius {
    border-bottom-left-radius: $bbraun-border-radius;
    border-bottom-right-radius: $bbraun-border-radius;
  }

  .inner-menu-item,
  .inner-filter-item {
    padding-left: 32px
  }

  mat-checkbox label {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    -webkit-line-clamp: 2;
  }
}

cx-table-menu-group-checkbox-item,
cx-table-filter-search:not(.cx-hide-search-divider),
cx-table-menu-item:not(:last-child),
cx-table-filter-item:not(:last-child) {
  position: relative;

  &::after {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: $bbraun-spacing-sm;
    right: $bbraun-spacing-sm;
    background-color: $bbraun-gray-30;
  }
}

.cx-main-checkbox-dropdown,
.cx-cell-dropdown-menu,
.cx-table-paginator-select {
  outline: 1px solid $bbraun-gray-30 !important;
}
