@import 'utilities/variables'

$material-design-icons-font-directory-path: '../../../node_modules/material-design-icons-iconfont/dist/fonts/'
@import 'material-design-icons-iconfont/src/material-design-icons'

.container-border
  border: 1px solid $bbraun-gray-40
  border-radius: $bbraun-border-radius

.container-green-border
  border: 1px solid $bbraun-green-60
  border-radius: $bbraun-border-radius

cx-message.create-page-message-height-modifier
  .cx-message-page-height
    height: calc(100vh - 76px - 128px - 70px) //removed header and add item to list components height and content margins

cx-message.welcome-page-message
  .cx-message-page-height
    height: calc(100vh + 104px)

.section-container
  max-width: 904px

.customer-section-margins
  margin-right: 40px
  margin-left: 40px
  margin-bottom: 181px

.mat-mdc-tab-group .mat-mdc-tab-header
  background: $bbraun-space
  border-bottom: 0

.tabs-fixed-position.mat-mdc-tab-group
  .mat-mdc-tab-header
    position: sticky
    top: 0

.tab-content
  display: flex
  flex-direction: column

$sidebar-full-width-override: 420px
$sidebar-with-padding-full-width-override: calc($sidebar-full-width-override + 24px) // add padding to the right of the tabs

.sidebar-tabs .mat-mdc-tab-header
  padding-bottom: 24px
  &:before
    content: ''
    position: absolute
    width: 100%
    height: 24px
    top: 93px
    background-color: $bbraun-space

mat-icon.repair-icon
  fill: $bbraun-green-80
mat-icon.purchase-icon
  fill: $bbraun-purple-100

.cx-navigation-drawer-headline
  display: flex
  flex-grow: 1
