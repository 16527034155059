/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */
@use '../../utilities/variables' as *;

// Form fields
.mat-form-field-appearance-outline {
  &.cx-no-subscript {
    .mat-mdc-text-field-wrapper {
      margin-top: 0;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  &.cx-readonly {
    .mat-mdc-form-field-hint {
      display: none;
    }
  }

  &.cx-readonly:not(.mdc-text-field--disabled) {
    ::placeholder {
      visibility: hidden;
    }

    .mdc-notched-outline__trailing,
    .mdc-notched-outline__leading {
      border: none !important;
    }

    .mat-mdc-select-arrow-wrapper,
    .mat-datepicker-toggle {
      display: none;
    }

    &.mat-mdc-form-field-type-mat-select,
    &.mat-mdc-form-field-type-mat-select
      .mat-mdc-floating-label.mdc-floating-label,
    &.mat-mdc-form-field-type-mat-date-range-input {
      pointer-events: none !important;
    }

    .mat-mdc-select-value-text,
    .mat-date-range-input,
    .mat-mdc-input-element.mdc-text-field__input {
      color: $bbraun-non-clickable;
    }

    .mat-mdc-text-field-wrapper {
      background: $bbraun-space;
      border: 1px dotted $bbraun-disabled;
    }
  }

  &.cx-top-prefix-and-suffix {
    .mat-mdc-form-field-text-suffix,
    .mat-mdc-form-field-text-prefix,
    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix {
      margin: 0 auto auto !important;
    }
  }
}
