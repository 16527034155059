/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

// CxCarousel Component
.mat-mdc-icon-button {
  &.cx-carousel-nav-button {
    line-height: 0 !important;
  }
}
