/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */

// Tabs
.mdc-tab.mat-mdc-tab {
  font-weight: bold;
  color: $bbraun-clickable;
  font-size: $bbraun-font-size-big;
  border-radius: $bbraun-border-radius $bbraun-border-radius 0 0;
  flex-grow: 0 !important;
  padding: 0 48px !important;
  opacity: 1 !important;
  overflow: hidden;

  &:hover {
    background: rgba($bbraun-clickable, $bbraun-opacity-hover);
  }

  .mdc-tab__ripple {
    display: none;
  }

  .mat-ripple-element {
    background: $bbraun-clickable;
  }

  .mdc-tab__text-label {
    display: flex;
    align-items: center;
    transition: none;
  }

  & .mat-icon {
    @extend .cx-icon-in-button;
  }

  &.mdc-tab--active {
    color: $bbraun-active;

    &:hover {
      background: rgba($bbraun-active, $bbraun-opacity-hover);
    }

    .mat-ripple-element {
      background: $bbraun-active;
    }
  }
}

.mat-mdc-tab-group .mat-mdc-tab-header {
  z-index: 10;
  background: $bbraun-space;
}

.mat-mdc-tab-body {
  & .mat-mdc-tab-body-content {
    transform: none !important;
    animation: fade-out 0.5s both;
  }

  &.mat-mdc-tab-body-active {
    & .mat-mdc-tab-body-content {
      animation: fade-in 0.5s both;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
