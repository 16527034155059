/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

// CxRangeInput Component
// Custom cx-digits-* classes
@for $i from 2 through 5 {
  cx-range-input.cx-digits-#{$i} {
    .mat-form-field-appearance-outline.cx-number-input-form-field {
      width: calculate-width($i);
    }
  }
  cx-range-input.cx-min-digits-#{$i} {
    .mat-form-field-appearance-outline.cx-number-input-form-field {
      min-width: calculate-width($i);
    }
  }
}
