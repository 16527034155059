/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */
@use '../../utilities/variables' as *;

button.mat-mdc-icon-button .mat-icon,
button.mat-mdc-button .mat-icon {
  z-index: initial;
}

// Badge
.mat-badge-small .mat-badge-content {
  width: $bbraun-font-size-medium;
  height: $bbraun-font-size-medium;
  line-height: $bbraun-font-size-big;
  font-size: 8px;
}

.mat-badge-small.mat-badge-above {
  .mat-badge-content {
    top: -3px;
  }
}

.mat-badge-small.mat-badge-overlap.mat-badge-after {
  .mat-badge-content {
    right: -3px;
  }
}
