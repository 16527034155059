/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

// CxList Component
.cx-list-title {
  border-bottom: 1px solid $bbraun-gray-30;
}

.cx-list-gutterless .cx-list,
.cx-list.cx-description-list {
  box-shadow: none !important;
  border-radius: 0 !important;

  .cx-list-title {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .cx-list-item {
    padding-left: 0 !important;
    padding-right: 0 !important;

    &::after {
      left: 0 !important;
      right: 0 !important;
    }
  }
}

.cx-list {
  min-width: fit-content;
  overflow: hidden;
  background-color: $bbraun-space;
  border-radius: $bbraun-border-radius;

  .mat-mdc-checkbox,
  .mat-mdc-radio-button {
    margin-top: 0;
  }

  &::before,
  &::after {
    content: '';
    display: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &.cx-regular-list {
    box-shadow: $bbraun-shadow-s;
  }

  &.cx-list-divided ul {
    cx-description-list-item:not(:last-child),
    cx-list-item:not(:last-child),
    cx-list-option:not(:last-child) {
      position: relative;

      .cx-list-item,
      .cx-list-option {
        position: relative;

        &::after {
          content: '';
          display: block;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: $bbraun-spacing-sm;
          right: $bbraun-spacing-sm;
          background-color: $bbraun-gray-30;
        }
      }
    }
  }

  .cx-list-option-selected .cx-list-item {
    background-color: rgba($bbraun-active, 0.08) !important;
  }

  // Remove this code when Firefox will finally support :has selector
  @supports not selector(:has(*)) {
    .cx-list-menu-template cx-menu,
    .cx-list-content-template > *:first-child {
      margin-left: $bbraun-spacing-xs;
    }

    .cx-list-thumbnail-template cx-image-thumbnail .cx-image-container,
    .cx-list-drag-handle-template mat-icon.cx-list-drag-handle {
      margin-right: $bbraun-spacing-s;
    }
  }
}

cx-list,
cx-listbox,
cx-flat-listbox,
cx-flat-list {
  &.cx-list-clickable {
    .cx-list-item,
    .cx-flat-list-option,
    .cx-list-option {
      @extend .clickable;

      &:hover {
        background: rgba($bbraun-clickable, $bbraun-opacity-hover);
      }

      &:active {
        background: rgba($bbraun-clickable, $bbraun-opacity-active);
      }
    }
  }
}

// CDK Drag'n'Drop in CxList and CXTableMenu
cx-list,
cx-listbox,
.cx-table-menu-drag-zone {
  &.cdk-drop-list {
    cx-list-item.cdk-drag,
    cx-list-option.cdk-drag,
    cx-table-menu-item.cdk-drag {
      display: flex;
      background: $bbraun-space;
    }
  }

  cx-list-item,
  cx-table-menu-item {
    flex-grow: 1 !important;
  }

  // Remove @supports when Firefox will finally support :has selector
  @supports selector(:has(*)) {
    .cdk-drag:not(.cdk-option, :has(.cdk-drag-handle)),
    .cdk-drag-handle {
      cursor: move !important;
    }
  }

  // Remove this code when Firefox will finally support :has selector
  @supports not selector(:has(*)) {
    .cursor-move,
    .cdk-drag-handle {
      cursor: move !important;
    }
  }

  .cdk-drag-placeholder {
    background: $bbraun-purple-20;
    border: dashed 1px $bbraun-accent;
    min-height: 56px;
  }

  .cdk-drag-animating,
  .cdk-drag-placeholder,
  &.cdk-drop-list-dragging {
    cx-list-item,
    cx-list-option,
    cx-table-menu-item {
      &:not(.cdk-drag-placeholder) {
        cursor: move;
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
}

cx-list-item,
cx-list-option,
cx-table-menu-item {
  &.cdk-drag-preview {
    border: 1px solid $bbraun-accent;
    box-shadow: $bbraun-shadow-l;
    background: $bbraun-space;
  }
}

.cx-list-drag-handle-secondary {
  margin-left: $bbraun-spacing-xs;
  color: $bbraun-gray-60;
}

cx-list,
cx-listbox,
cx-flat-list {
  &.cx-list-missing-icons-align {
    .cx-list-item .cx-list-fill-icon-space {
      margin-left: 36px;
    }
  }
}
