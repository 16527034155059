/**
 * Definitions of B.Braun typography customization which is an extension of Angular Material's Sass-based theming.
 * The official font for BBraun web applications is Arial.
 */
@use '../utilities/variables' as *;

.font {
  &-l {
    font-family: $bbraun-font-family !important;
    font-size: $bbraun-font-size-big !important;
  }

  &-m {
    font-family: $bbraun-font-family !important;
    font-size: $bbraun-font-size-medium !important;
  }

  &-s {
    font-family: $bbraun-font-family !important;
    font-size: $bbraun-font-size-small !important;
  }
}

h1,
h2,
h3,
h4 {
  font-family: $bbraun-font-family !important;
}

h1 {
  font-size: 36px;
}

h2,
cx-header-title h1 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}
