/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

// CxFooter Component
.cx-footer {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  box-sizing: border-box;
  background-color: $bbraun-background !important;
  height: 48px !important;
  z-index: 1000 !important;
  overflow: clip;

  .mat-mdc-button.mat-mdc-button-base {
    text-transform: capitalize !important;
    font-weight: bold !important;
    font-size: $bbraun-font-size-medium;
    width: 100% !important;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
    height: calc(48px + $bbraun-border-radius) !important;

    &.active {
      color: $bbraun-active !important;

      &:hover > .mat-mdc-button-persistent-ripple {
        background: $bbraun-active !important;
        opacity: $bbraun-opacity-hover !important;
      }

      .mat-ripple-element {
        background: $bbraun-active !important;
        opacity: $bbraun-opacity-active !important;
      }
    }
  }

  .cx-badge-notification-md-icon,
  .cx-badge-notification-sm-icon {
    left: calc(24px / 2) !important;
  }
}
