/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */
@use '../../../styles/base/global-classes' as *;

// CxImage Component
.cx-image-fit-cover .cx-image {
  object-fit: cover !important;
}

cx-image-thumbnail.cx-image-border-radius .cx-image-container {
  @extend .border-radius;
  overflow: hidden;
}

cx-image-thumbnail.cx-image-crop-circle .cx-image-container {
  border-radius: 50%;
  overflow: hidden;
}
