/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */
@use '../../utilities/variables' as *;

cx-expansion-panel .mat-expansion-panel-header {
  padding: 0 $bbraun-spacing-sm;
  height: 56px;
}

cx-expansion-panel mat-panel-title {
  height: 24px;
}

cx-expansion-panel .mat-expansion-panel-body {
  padding: 0 $bbraun-spacing-sm $bbraun-spacing-sm;
}

.mat-accordion > cx-expansion-panel > .mat-expansion-panel:not(.mat-expanded){
  border-radius: 0;
  border-top: none;
}

.mat-accordion > cx-expansion-panel > .mat-expansion-panel:has(.mat-expanded){
  border-radius: $bbraun-border-radius;
  margin: $bbraun-spacing-s 0;
}

.cx-expansion-panel {
  box-shadow: none !important;
  border: 1px solid $bbraun-gray-60;
}

cx-expansion-panel .mat-expansion-panel-header-title {
  font: $bbraun-font-size-big $bbraun-font-family;
}

mat-accordion > cx-expansion-panel:has(.mat-expanded) + cx-expansion-panel > .cx-expansion-panel{
  border-top: 1px solid $bbraun-gray-60 !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

cx-expansion-panel:last-child > .cx-expansion-panel {
  border-bottom-right-radius: $bbraun-border-radius !important;
  border-bottom-left-radius: $bbraun-border-radius !important;
  border-bottom:  1px solid $bbraun-gray-60 !important;
}

cx-expansion-panel:first-child > .cx-expansion-panel {
  border-top-right-radius: $bbraun-border-radius !important;
  border-top-left-radius: $bbraun-border-radius !important;
  border-top:1px solid $bbraun-gray-60 !important;
}
