/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */
@use '../../utilities/variables' as *;

// CxSearchBar Component
.cx-autocomplete-for-search-bar.mat-mdc-autocomplete-panel {
  .mdc-list-item__primary-text {
    width: 100%;

    & > *:not(span) {
      position: absolute;
    }

    & > span {
      margin: $bbraun-spacing-s 0;
      position: relative;
      left: 36px;
      width: calc(100% - 36px);
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;
    }
  }
}
