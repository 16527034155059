/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

// CxHeartBeat Component
.cx-dialog-heart-beat {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding-top: 0;
  }
}
