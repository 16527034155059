/**
 * Definitions of global classes, which can be used by the applications importing the Cortex Web Angular.
 * None of them have cx- prefix. They can be used anywhere.
 */

$cx-number-btn-size: 48px;

// Icons
.x-small-icon {
  height: $bbraun-spacing-sm !important;
  width: $bbraun-spacing-sm !important;
  font-size: $bbraun-spacing-sm !important;
}

.small-icon {
  height: $bbraun-spacing-m !important;
  width: $bbraun-spacing-m !important;
  font-size: $bbraun-spacing-m !important;
}

.medium-icon {
  height: $bbraun-spacing-ml !important;
  width: $bbraun-spacing-ml !important;
  font-size: $bbraun-spacing-ml !important;
}

.large-icon {
  height: $bbraun-spacing-l !important;
  width: $bbraun-spacing-l !important;
  font-size: $bbraun-spacing-l !important;
}

// Generate variations of icon classes
@include generate-icon-classes('green', $bbraun-active);
@include generate-icon-classes('gray', $bbraun-clickable);
@include generate-icon-classes('white', $bbraun-space);
@include generate-icon-classes('purple', $bbraun-accent);

// Generate variations of color classes
@include generate-color-hover-classes(
  'green',
  $bbraun-active,
  $bbraun-green-80
);
@include generate-color-hover-classes(
  'gray',
  $bbraun-clickable,
  $bbraun-gray-100
);
@include generate-color-hover-classes('white', $bbraun-space, $bbraun-gray-20);
@include generate-color-hover-classes(
  'purple',
  $bbraun-accent,
  $bbraun-purple-110
);

.link {
  font-size: $bbraun-font-size-big !important;
}

.small-link {
  font-size: $bbraun-font-size-medium !important;
}

.link,
.small-link {
  cursor: pointer;
  font-family: $bbraun-font-family !important;
  color: $bbraun-links !important;
  text-decoration: underline !important;
  transition: 0.1s color ease-out !important;

  &:hover {
    color: $bbraun-blue-90 !important;
  }
}

$global-definitions: (
  'font-weight': (
    values: (
      light: $bbraun-font-weight-light,
      bold: $bbraun-font-weight-bold,
      semi-bold: $bbraun-font-weight-semi-bold,
      normal: $bbraun-font-weight-normal
    )
  ),
  'text-transform': (
    class: text,
    values: lowercase uppercase capitalize
  ),
  'text-align': (
    class: text,
    values: left right center
  ),
  'overflow': (
    values: visible hidden auto scroll clip
  )
);
$colors-map: (
  // White
  white: $bbraun-space,
  // Green
  green: $bbraun-active,
  green-10: $bbraun-green-10,
  green-20: $bbraun-green-20,
  green-30: $bbraun-green-30,
  green-40: $bbraun-green-40,
  green-50: $bbraun-green-50,
  green-60: $bbraun-green-60,
  green-70: $bbraun-green-70,
  green-80: $bbraun-green-80,
  green-90: $bbraun-green-90,
  green-100: $bbraun-green-100,
  green-110: $bbraun-green-110,
  green-120: $bbraun-green-120,
  // Purple
  purple: $bbraun-accent,
  purple-10: $bbraun-purple-10,
  purple-20: $bbraun-purple-20,
  purple-30: $bbraun-purple-30,
  purple-40: $bbraun-purple-40,
  purple-50: $bbraun-purple-50,
  purple-60: $bbraun-purple-60,
  purple-70: $bbraun-purple-70,
  purple-80: $bbraun-purple-80,
  purple-90: $bbraun-purple-90,
  purple-100: $bbraun-purple-100,
  purple-110: $bbraun-purple-110,
  purple-120: $bbraun-purple-120,
  // Black
  black: $bbraun-non-clickable,
  // Gray
  gray: $bbraun-clickable,
  gray-10: $bbraun-gray-10,
  gray-20: $bbraun-gray-20,
  gray-30: $bbraun-gray-30,
  gray-40: $bbraun-gray-40,
  gray-50: $bbraun-gray-50,
  gray-60: $bbraun-gray-60,
  gray-70: $bbraun-gray-70,
  gray-80: $bbraun-gray-80,
  gray-90: $bbraun-gray-90,
  gray-100: $bbraun-gray-100,
  gray-110: $bbraun-gray-110,
  gray-120: $bbraun-gray-120,
  // Sand
  sand-10: $bbraun-sand-10,
  sand-20: $bbraun-sand-20,
  sand-30: $bbraun-sand-30,
  sand-40: $bbraun-sand-40,
  sand-50: $bbraun-sand-50,
  sand-60: $bbraun-sand-60,
  sand-70: $bbraun-sand-70,
  sand-80: $bbraun-sand-80,
  sand-90: $bbraun-sand-90,
  sand-100: $bbraun-sand-100,
  sand-110: $bbraun-sand-110,
  sand-120: $bbraun-sand-120,
  // Blue
  blue: $bbraun-links,
  blue-10: $bbraun-blue-10,
  blue-20: $bbraun-blue-20,
  blue-30: $bbraun-blue-30,
  blue-40: $bbraun-blue-40,
  blue-50: $bbraun-blue-50,
  blue-60: $bbraun-blue-60,
  blue-70: $bbraun-blue-70,
  blue-80: $bbraun-blue-80,
  blue-90: $bbraun-blue-90,
  blue-100: $bbraun-blue-100,
  blue-110: $bbraun-blue-110,
  blue-120: $bbraun-blue-120,
  // Light green
  light-green-10: $bbraun-light-green-10,
  light-green-20: $bbraun-light-green-20,
  light-green-30: $bbraun-light-green-30,
  light-green-40: $bbraun-light-green-40,
  light-green-50: $bbraun-light-green-50,
  light-green-60: $bbraun-light-green-60,
  light-green-70: $bbraun-light-green-70,
  light-green-80: $bbraun-light-green-80,
  light-green-90: $bbraun-light-green-90,
  light-green-100: $bbraun-light-green-100,
  light-green-110: $bbraun-light-green-110,
  light-green-120: $bbraun-light-green-120,
  // Red
  red: $bbraun-error,
  red-10: $bbraun-red-10,
  red-20: $bbraun-red-20,
  red-30: $bbraun-red-30,
  red-40: $bbraun-red-40,
  red-50: $bbraun-red-50,
  red-60: $bbraun-red-60,
  red-70: $bbraun-red-70,
  red-80: $bbraun-red-80,
  red-90: $bbraun-red-90,
  red-100: $bbraun-red-100,
  red-110: $bbraun-red-110,
  red-120: $bbraun-red-120,
  // Orange
  yellow: $bbraun-warning,
  orange-10: $bbraun-orange-10,
  orange-20: $bbraun-orange-20,
  orange-30: $bbraun-orange-30,
  orange-40: $bbraun-orange-40,
  orange-50: $bbraun-orange-50,
  orange-60: $bbraun-orange-60,
  orange-70: $bbraun-orange-70,
  orange-80: $bbraun-orange-80,
  orange-90: $bbraun-orange-90,
  orange-100: $bbraun-orange-100,
  orange-110: $bbraun-orange-110,
  orange-120: $bbraun-orange-120
);
$bg-atomic-color-definitions: (
  'background-color': (
    class: bg,
    values: $colors-map
  ),
  'color': (
    class: text,
    values: $colors-map
  )
);

@include generate-classes($global-definitions, true);
@include generate-classes($bg-atomic-color-definitions);

.clickable {
  cursor: pointer !important;
  user-select: none !important;
}

.unclickable {
  cursor: default !important;
  pointer-events: none !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.border-radius {
  border-radius: $bbraun-border-radius !important;
}
