/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */
@use '../../utilities/variables' as *;

// All outline inputs
.mat-form-field-appearance-outline {
  input::placeholder {
    color: rgba($bbraun-clickable, $bbraun-opacity-disabled) !important;
  }

  &:not(.cx-readonly, .mat-focused, .mat-form-field-invalid),
  .mdc-text-field--outlined:not(
      [class*='--focused'],
      [class*='--disabled']
    ):hover {
    & .mdc-notched-outline {
      & .mdc-notched-outline__trailing,
      & .mdc-notched-outline__leading {
        border-color: transparent;
      }
    }
  }

  &.mat-focused:has([class*='--focused']) .mdc-notched-outline {
    & .mdc-notched-outline__trailing,
    & .mdc-notched-outline__leading {
      border-color: $bbraun-active;
    }
  }

  &.mat-form-field-invalid:has([class*='--invalid']) .mdc-notched-outline {
    & .mdc-notched-outline__trailing,
    & .mdc-notched-outline__leading {
      border-color: $bbraun-error;
    }
  }

  .mat-mdc-text-field-wrapper {
    margin-top: 24px;
    background: $bbraun-background;
    border-radius: $bbraun-border-radius;
    padding: 0;

    .mat-mdc-form-field-flex {
      padding: 0 $bbraun-spacing-sm;

      & > .mdc-notched-outline {
        .mdc-notched-outline__trailing {
          border-width: 1px !important;
          border-top-right-radius: $bbraun-border-radius;
          border-bottom-right-radius: $bbraun-border-radius;
        }

        .mdc-notched-outline__leading {
          border-width: 1px !important;
          border-top-left-radius: $bbraun-border-radius;
          border-bottom-left-radius: $bbraun-border-radius;
        }

        .mdc-notched-outline__notch {
          top: -24px;
          left: 0;
          right: 0;
          position: absolute;
          padding: 0;
          border: none;
          max-width: calc(100% + 12px * 2) !important;
          width: 100% !important;

          .mat-mdc-floating-label {
            top: 0;
            left: 0;
            color: $bbraun-non-clickable;
            font-size: $bbraun-font-size-medium;
            transform: none !important;
            text-overflow: ellipsis;
            max-width: 100%;

            .mat-mdc-form-field-required-marker::after {
              color: $bbraun-error;
              font-weight: bold;
              font-size: $bbraun-font-size-big;
              top: 0;
              left: 0;
            }

            @supports selector(:has(*)) {
              mat-label:has(+ .mat-mdc-form-field-required-marker) {
                margin-left: 10px;
              }

              .mat-mdc-form-field-required-marker::after {
                position: absolute;
              }
            }

            @supports not selector(:has(*)) {
              .mat-mdc-form-field-required-marker::after {
                position: relative;
                margin-right: 2px;
                margin-top: -1px;
                float: left;
              }
            }
          }

          @supports not selector(:has(*)) {
            .mat-mdc-floating-label {
              line-height: inherit;
            }
          }
        }
      }

      .mat-mdc-form-field-infix {
        padding: 10px 0;
        color: $bbraun-clickable;
        min-height: 44px;
      }
    }

    &:hover:not(
        [class*='--focused'],
        [class*='--invalid'],
        [class*='--disabled']
      )
      .mdc-notched-outline {
      & .mdc-notched-outline__trailing,
      & .mdc-notched-outline__leading {
        border-color: rgba($bbraun-clickable, $bbraun-opacity-hover) !important;
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 20px;
    margin-top: 2px;
    line-height: 17px;

    & > .mat-mdc-form-field-error-wrapper,
    & > .mat-mdc-form-field-hint-wrapper {
      padding: 0;
    }

    .mat-mdc-form-field-error {
      display: flex;

      &::before {
        font-family: 'Material Icons', sans-serif;
        font-size: $bbraun-font-size-big;
        content: 'warning';
        margin-right: 6px;
      }
    }
  }

  // Date input
  &:not([disabled], .cx-readonly) .mat-date-range-input-separator {
    color: $bbraun-clickable;
  }

  .mat-datepicker-toggle {
    position: relative;
    left: 11px;
    top: 1px;
  }

  &.mat-focused:not(.ng-invalid, .cx-readonly, .mat-form-field-invalid)
    .mat-datepicker-toggle
    .mat-mdc-icon-button {
    color: $bbraun-active;
  }

  &.ng-invalid, &.mat-form-field-invalid {
    .mat-datepicker-toggle .mat-mdc-icon-button {
      color: $bbraun-error;
    }
  }

  // Disabled state
  &.mat-form-field-disabled {
    .mat-mdc-form-field-hint {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      background-color: $bbraun-gray-10;
    }

    .mat-mdc-floating-label,
    .mat-mdc-select-value-text,
    .mat-datepicker-toggle,
    .mat-datepicker-toggle .mat-mdc-icon-button,
    .mat-mdc-select-disabled .mat-mdc-select-arrow {
      color: $bbraun-disabled !important;
    }
  }

  // Select
  &.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper:hover {
    cursor: pointer;
  }

  .mat-mdc-select-value-text,
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: $bbraun-clickable;
  }
}
