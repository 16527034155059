/**
 * Defines basic styles.
 */

html,
body {
  font-family: $bbraun-font-family;
  line-height: $bbraun-line-height;
  color: $bbraun-non-clickable;
  margin: 0;
  padding: 0;
}

* {
  outline: none;
}

input {
  caret-color: $bbraun-active;
}

// Highlights override
// IE9 and newer
::selection {
  background: $bbraun-accent;
  color: $bbraun-space;
}

// Safari & Chrome
::-webkit-selection {
  background: $bbraun-accent;
  color: $bbraun-space;
}

:disabled {
  &::selection {
    background: $bbraun-purple-40;
  }

  &::-webkit-selection {
    background: $bbraun-purple-40;
  }
}
