/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */
@use '../../utilities/variables' as *;

// Dialog
.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: $bbraun-spacing-ml $bbraun-spacing-ml $bbraun-spacing-m;
    border-radius: $bbraun-border-radius;

    .mat-mdc-dialog-title.mdc-dialog__title {
      padding: 0;
      color: $bbraun-non-clickable;
      line-height: $bbraun-line-height;
      margin-bottom: $bbraun-spacing-m;

      &::before {
        display: none;
      }
    }

    .mat-mdc-dialog-content.mdc-dialog__content {
      color: $bbraun-non-clickable;
      padding: 0;
    }

    .mat-mdc-dialog-actions {
      padding: 0;
      min-height: auto;
    }
  }
}
