/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */
@use '../../utilities/variables' as *;

// Calendar
.mat-calendar {
  button {
    background: transparent;
    color: $bbraun-clickable;
    border: none;

    &:hover {
      background: transparent;
      color: $bbraun-clickable;
    }
  }

  .mat-mdc-icon-button {
    width: 40px !important;
    height: 40px !important;
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
    border-color: $bbraun-accent !important;
  }
}
