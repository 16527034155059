@mixin generate-icon-classes($class, $color) {
  .#{$class}-small-icon {
    @extend .small-icon;
    color: $color !important;
  }

  .#{$class}-medium-icon {
    @extend .medium-icon;
    color: $color !important;
  }

  .#{$class}-large-icon {
    @extend .large-icon;
    color: $color !important;
  }
}
