/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

#cx-devtools {
  .cx-floating-base-button {
    right: unset;
    left: 96px;
    z-index: 1000;
  }

  .cx-floating-button-menu {
    right: unset !important;
    left: 5% !important;
    z-index: 1000;
    
    .cx-button-menu-item {
      right: 0;
    }
  }
}
