@import 'utilities/variables'

.mat-mdc-menu-panel.cx-main-checkbox-dropdown
  max-height: 305px

  cx-menu-item.disable-hover:hover button
    background-color: transparent

  cx-menu-item:not(.disable-hover) button
    padding: 0 32px


.nd-container
  @media (min-width: 1000px)
    margin-left: 24px !important
    margin-right: 24px !important
    max-width: unset !important
  @media screen and (max-width: 1536px)
    padding: unset !important

button.show-hover
  .mat-mdc-button-persistent-ripple
    background-color: $bbraun-green-60
    opacity: 0.16 !important
