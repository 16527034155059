/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */
@use '../../utilities/variables' as *;

%truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// CxProfileDropdown Component
cx-profile-dropdown
  .cx-menu-button-opened
  .mat-mdc-icon-button.cx-profile-dropdown-trigger {
  background: rgba($bbraun-space, $bbraun-opacity-active) !important;
}

.cx-profile-dropdown-menu {
  &.mat-mdc-menu-panel {
    // .mat-mdc-menu-panel twice, to override material styles without !important
    &.mat-mdc-menu-panel {
      width: $cx-profile-dropdown-width;
      max-width: $cx-profile-dropdown-width;
      min-width: $cx-profile-dropdown-width;
    }

    .mat-mdc-menu-item {
      .mdc-list-item__primary-text span:has(+ .x-small-icon) {
        @extend %truncate;
        width: calc(
          $cx-profile-dropdown-width - $bbraun-spacing-l - $bbraun-spacing-s -
            $bbraun-spacing-sm - $bbraun-spacing-m
        );
      }
    }

    .cx-profile-dropdown-data {
      @extend %truncate;
      width: calc(
        $cx-profile-dropdown-width - $bbraun-spacing-l - $bbraun-spacing-s -
          $bbraun-spacing-sm
      );
    }
  }
}
