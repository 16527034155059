/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

// CxNumberInput Component
$cx-number-base: 49px;
$cx-number-step: 9px;

@function calculate-width($i) {
  @return calc(
    $cx-number-base + $cx-number-step * ($i - 2) + $cx-number-btn-size * 2
  );
}

.mat-form-field-appearance-outline {
  &.cx-number-input-form-field {
    // Custom cx-digits-* classes
    @for $i from 2 through 5 {
      &.cx-digits-#{$i} {
        width: calculate-width($i);
      }

      &.cx-min-digits-#{$i}{
        min-width: calculate-width($i);
      }
    }

    &.cx-disable-hover-outline {
      & .mdc-notched-outline__trailing,
      & .mdc-notched-outline__leading {
        border-color: transparent !important;
      }
    }

    &:not(.cx-readonly) {
      min-width: calc(3 * $cx-number-btn-size);

      .mat-mdc-text-field-wrapper {
        flex: none;
        width: calc(100% - $cx-number-btn-size * 2) !important;
        margin-right: $cx-number-btn-size;
        margin-left: $cx-number-btn-size;

        .mat-mdc-form-field-flex {
          min-width: $cx-number-btn-size;
        }

        .mdc-notched-outline__notch {
          left: -$cx-number-btn-size;
          max-width: calc(100% + $cx-number-btn-size * 2) !important;
          width: calc(100% + $cx-number-btn-size * 2) !important;

          .mat-mdc-floating-label {
            width: 100%;
          }
        }
      }
    }

    &.cx-readonly {
      .cx-number-input-button {
        display: none;
      }
    }
  }
}
