/**
 * Definitions of layout related global classes, which can be used by the applications importing the Cortex Web Angular.
 * None of them have cx- prefix. They can be used anywhere.
 */

/* stylelint-disable length-zero-no-unit */

@use 'sass:math';

$bbraun-max-margin-breakpoint: 1432px;

@mixin generate-responsive-classes($nd-width: 0px) {
  $nd-prefix: '';
  @if $nd-width != 0px {
    $nd-prefix: 'nd-';
  }

  .#{$nd-prefix}container {
    max-width: 1372px !important;
    width: inherit !important;
    margin: 0 auto !important;

    @media screen and (max-width: calc($bbraun-max-margin-breakpoint + $nd-width)) {
      padding: 0 $bbraun-l-gutter !important;
    }

    @media screen and (max-width: calc($bbraun-l-breakpoint + $nd-width)) {
      padding: 0 $bbraun-m-gutter !important;
    }

    @media screen and (max-width: calc($bbraun-m-breakpoint + $nd-width)) {
      padding: 0 $bbraun-s-gutter !important;
    }
  }

  .#{$nd-prefix}container-with-grid {
    @extend .#{$nd-prefix}container;
    display: grid !important;
    column-gap: $bbraun-xl-gutter !important;
    row-gap: $bbraun-m-gutter !important;

    @media screen and (max-width: calc($bbraun-xl-breakpoint + $nd-width)) {
      column-gap: $bbraun-l-gutter !important;
    }

    @media screen and (max-width: calc($bbraun-l-breakpoint + $nd-width)) {
      column-gap: $bbraun-m-gutter !important;
    }

    @media screen and (max-width: calc($bbraun-m-breakpoint + $nd-width)) {
      column-gap: $bbraun-s-gutter !important;
    }
  }

  .#{$nd-prefix}container-fluid {
    @extend .#{$nd-prefix}container;
    max-width: 100% !important; // due to important flag in the container class

    @media screen and (min-width: calc($bbraun-l-breakpoint + $nd-width + 1px)) {
      padding: 0 $bbraun-l-gutter !important; // due to important flag in the container class
    }
  }

  .#{$nd-prefix}d-grid,
  .#{$nd-prefix}gap {
    --bbraun-gutter: #{$bbraun-xl-gutter};
    column-gap: var(--bbraun-gutter);
    row-gap: $bbraun-m-gutter !important;

    @media screen and (max-width: calc($bbraun-xl-breakpoint + $nd-width)) {
      column-gap: $bbraun-l-gutter !important;

      --bbraun-gutter: #{$bbraun-l-gutter};
    }

    @media screen and (max-width: calc($bbraun-l-breakpoint + $nd-width)) {
      --bbraun-gutter: #{$bbraun-m-gutter};
      column-gap: $bbraun-m-gutter !important;
    }

    @media screen and (max-width: calc($bbraun-m-breakpoint + $nd-width)) {
      --bbraun-gutter: #{$bbraun-s-gutter};
      column-gap: $bbraun-s-gutter !important;
    }
  }
}

@include generate-responsive-classes;
@include generate-responsive-classes($cx-navigation-drawer-width);

$columns: 12;

.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
}

.col {
  flex: 1 0 0%;
}

@mixin generate-columns($infix: '', $prefix: '') {
  @if $infix != '' {
    $infix: '-' + $infix;

    .#{$prefix}offset#{$infix}-0 {
      margin-left: 0;
    }
  }

  @for $i from 1 through $columns {
    $column-size: math.percentage(math.div($i, $columns));
    $gutter-multiplier: math.div($columns - $i, $columns);
    $calc-expr: calc(
      $column-size - var(--bbraun-gutter, 0px) * $gutter-multiplier
    );

    .flex-nowrap .#{$prefix}col#{$infix}-#{$i} {
      flex: 0 1 auto;
    }
    .#{$prefix}col#{$infix}-#{$i} {
      flex: 0 0 $calc-expr;
      max-width: $calc-expr;
    }
    .#{$prefix}offset#{$infix}-#{$i} {
      margin-left: calc($calc-expr + var(--bbraun-gutter, 0px));
    }
  }
}

@include generate-columns;

@each $infix, $breakpoint in $breakpoints {
  @media screen and (min-width: $breakpoint) {
    @include generate-columns($infix);
  }

  @media screen and (min-width: calc($breakpoint + $cx-navigation-drawer-width)) {
    @include generate-columns($infix, 'nd-');
  }
}

$sizing: (
  0: 0,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
);
$layout-definitions: (
  width: (
    class: w,
    values: $sizing
  ),
  height: (
    class: h,
    values: $sizing
  ),
  max-height: (
    class: max-height,
    values: (
      100: 100%
    )
  ),
  max-width: (
    class: max-width,
    values: (
      100: 100%
    )
  ),
  position: (
    values: absolute fixed relative sticky
  ),
  display: (
    class: d,
    values: flex block inline inline-block none grid
  ),
  flex: (
    property: flex-direction,
    values: row row-reverse column column-reverse
  ),
  flex-wrap: (
    class: flex,
    property: flex-wrap,
    values: wrap nowrap
  ),
  flex-grow: (
    values: (
      0: 0,
      1: 1
    )
  ),
  flex-shrink: (
    values: (
      0: 0,
      1: 1
    )
  ),
  align-content: (
    values: (
      center: center,
      start: flex-start,
      end: flex-end,
      stretch: stretch,
      between: space-between,
      around: space-around
    )
  ),
  align-self: (
    values: (
      auto: auto,
      center: center,
      start: flex-start,
      end: flex-end,
      stretch: stretch,
      baseline: baseline
    )
  ),
  align-items: (
    values: (
      center: center,
      start: flex-start,
      end: flex-end,
      stretch: stretch,
      baseline: baseline
    )
  ),
  justify-content: (
    values: (
      center: center,
      start: flex-start,
      end: flex-end,
      between: space-between,
      evenly: space-evenly,
      around: space-around
    )
  ),
  float: (
    values: left right none
  ),
  cols: (
    property: grid-template-columns,
    values: (
      1: 100%,
      2: repeat(2, calc(50% - var(--bbraun-gutter) / 2)),
      3: repeat(3, calc(33.3% - var(--bbraun-gutter) / 3 * 2)),
      4: repeat(4, calc(25% - var(--bbraun-gutter) / 4 * 3))
    )
  )
);

@include generate-classes($layout-definitions, true);
