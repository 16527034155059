/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

// Checkbox
.cx-checkboxes-border.mat-mdc-checkbox {
  & ~ ul li:first-of-type .mat-mdc-checkbox {
    border-top: 1px solid $bbraun-gray-30;
    padding-top: 6px;
  }
}
