@use '../../utilities/variables' as *;

@mixin generate-spacing-classes($class, $property, $second-property: false) {
  .#{$class}-0 {
    #{$property}: 0 !important;
    @if $second-property {
      #{$second-property}: 0 !important;
    }
  }

  .#{$class}-auto {
    #{$property}: auto !important;
    @if $second-property {
      #{$second-property}: auto !important;
    }
  }

  .#{$class}-2xs {
    #{$property}: $bbraun-spacing-2xs !important;
    @if $second-property {
      #{$second-property}: $bbraun-spacing-2xs !important;
    }
  }

  .#{$class}-xs {
    #{$property}: $bbraun-spacing-xs !important;
    @if $second-property {
      #{$second-property}: $bbraun-spacing-xs !important;
    }
  }

  .#{$class}-s {
    #{$property}: $bbraun-spacing-s !important;
    @if $second-property {
      #{$second-property}: $bbraun-spacing-s !important;
    }
  }

  .#{$class}-sm {
    #{$property}: $bbraun-spacing-sm !important;
    @if $second-property {
      #{$second-property}: $bbraun-spacing-sm !important;
    }
  }

  .#{$class}-m {
    #{$property}: $bbraun-spacing-m !important;
    @if $second-property {
      #{$second-property}: $bbraun-spacing-m !important;
    }
  }

  .#{$class}-ml {
    #{$property}: $bbraun-spacing-ml !important;
    @if $second-property {
      #{$second-property}: $bbraun-spacing-ml !important;
    }
  }

  .#{$class}-l {
    #{$property}: $bbraun-spacing-l !important;
    @if $second-property {
      #{$second-property}: $bbraun-spacing-l !important;
    }
  }

  .#{$class}-xl {
    #{$property}: $bbraun-spacing-xl !important;
    @if $second-property {
      #{$second-property}: $bbraun-spacing-xl !important;
    }
  }

  .#{$class}-2xl {
    #{$property}: $bbraun-spacing-2xl !important;
    @if $second-property {
      #{$second-property}: $bbraun-spacing-2xl !important;
    }
  }

  .#{$class}-3xl {
    #{$property}: $bbraun-spacing-3xl !important;
    @if $second-property {
      #{$second-property}: $bbraun-spacing-3xl !important;
    }
  }
}
