/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */
@use '../../utilities/variables' as *;

// Chips
.mat-form-field-appearance-outline {
  // Disabled state
  &.cx-chips-disabled {
    .mat-mdc-form-field-hint {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      background-color: $bbraun-gray-10;
    }

    .mat-mdc-floating-label,
    .mat-mdc-select-value-text,
    .mat-datepicker-toggle,
    .mat-datepicker-toggle .mat-mdc-icon-button,
    .mat-mdc-select-disabled .mat-mdc-select-arrow {
      color: $bbraun-disabled !important;
    }

    & .mdc-notched-outline {
      & .mdc-notched-outline__trailing,
      & .mdc-notched-outline__leading {
        border-color: transparent !important;
      }
    }
  }
}

// looks weird but at least works in Firefox
.cx-chips-disabled,
.mat-mdc-form-field-type-mat-chip-grid.cx-readonly {
  input::placeholder {
    visibility: hidden;
    opacity: 0 !important;
  }
}

// Show more show less functionality
.mat-mdc-chip-grid:not(:has(.mdc-evolution-chip--disabled), [readonly='true']) {
  .cx-show-more-less-button,
  ~ .cx-show-more-less-button {
    display: none;
  }
}
