/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */
@use '../../../utilities/variables' as *;
@use './shared' as *;

// Radio
$radio-input-size: 20px;

.mdc-radio {
  padding: calc(($ripple-size - $radio-input-size) / 2) !important;

  .mdc-radio__background::before {
    top: calc(($ripple-size - $radio-input-size) / -2) !important;
    left: calc(($ripple-size - $radio-input-size) / -2) !important;
  }
}

.mat-mdc-radio-button.mat-primary {
  margin-top: $bbraun-spacing-2xs;

  .mdc-radio {
    .mat-ripple-element {
      opacity: $bbraun-opacity-active;
    }

    .mdc-radio__native-control {
      &:disabled:checked ~ .mdc-radio__background {
        .mdc-radio__outer-circle,
        .mdc-radio__inner-circle {
          opacity: $bbraun-opacity-disabled;
          border-color: $bbraun-active;
        }
      }

      &:disabled:not(:checked) ~ .mdc-radio__background {
        .mdc-radio__outer-circle,
        .mdc-radio__inner-circle {
          opacity: $bbraun-opacity-disabled;
        }
      }

      &:checked ~ .mat-radio-ripple .mat-ripple-element {
        background-color: $bbraun-active;
      }

      &:not(:checked) {
        & ~ .mat-radio-ripple .mat-ripple-element {
          background-color: $bbraun-clickable;
        }

        & ~ .mdc-radio__background .mdc-radio__outer-circle {
          border-color: $bbraun-clickable !important;
        }
      }
    }

    &:hover .mdc-radio__native-control:not(:disabled) {
      &:not(:checked) ~ .mdc-radio__background::before {
        background-color: $bbraun-clickable;
        opacity: $bbraun-opacity-hover;
      }

      &:checked ~ .mdc-radio__background::before {
        background-color: $bbraun-active;
        opacity: $bbraun-opacity-hover;
      }
    }
  }
}
