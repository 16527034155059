/* stylelint-disable scss/dollar-variable-empty-line-before */
// PRIMARY SHADES
$bbraun-green-10: #e0f8f2;
$bbraun-green-20: #baf4e6;
$bbraun-green-30: #5ddbbc;
$bbraun-green-40: #00c696;
$bbraun-green-50: #00b482;
$bbraun-green-60: #00a97a; // $bbraun-active
$bbraun-green-70: #009771;
$bbraun-green-80: #008762;
$bbraun-green-90: #007154;
$bbraun-green-100: #00664a;
$bbraun-green-110: #00513b;
$bbraun-green-120: #003929;

$bbraun-purple-10: #fbf4fc;
$bbraun-purple-20: #f3e6f6;
$bbraun-purple-30: #ead1ef;
$bbraun-purple-40: #e1b6e9;
$bbraun-purple-50: #db95e7;
$bbraun-purple-60: #d76eeb;
$bbraun-purple-70: #bd32d9;
$bbraun-purple-80: #ae44c1;
$bbraun-purple-90: #9e2ab5; // $bbraun-accent
$bbraun-purple-100: #832b93;
$bbraun-purple-110: #711e82;
$bbraun-purple-120: #4a1e52;

$bbraun-sand-10: #faf9f5;
$bbraun-sand-20: #f0ede6;
$bbraun-sand-30: #dfdbd3;
$bbraun-sand-40: #cac6bf;
$bbraun-sand-50: #b3b0a9;
$bbraun-sand-60: #9c9a94;
$bbraun-sand-70: #888681;
$bbraun-sand-80: #74746f;
$bbraun-sand-90: #64645f;
$bbraun-sand-100: #555451;
$bbraun-sand-110: #464643;
$bbraun-sand-120: #31312e;

$bbraun-gray-10: #f9f9f9;
$bbraun-gray-20: #ececec; // $bbraun-background
$bbraun-gray-30: #dbdbdb;
$bbraun-gray-40: #c6c6c6;
$bbraun-gray-50: #b0b0b0; // $bbraun-disabled
$bbraun-gray-60: #9a9a9a;
$bbraun-gray-70: #868686;
$bbraun-gray-80: #737373; // $bbraun-clickable
$bbraun-gray-90: #636363;
$bbraun-gray-100: #545454;
$bbraun-gray-110: #454545;
$bbraun-gray-120: #202020; // $bbraun-non-clickable

// SECONDARY SHADES
$bbraun-blue-10: #e3f3ff;
$bbraun-blue-20: #d3ecff;
$bbraun-blue-30: #aadeff;
$bbraun-blue-40: #6eceff;
$bbraun-blue-50: #04bcf4;
$bbraun-blue-60: #06adea;
$bbraun-blue-70: #2da0d3; // $bbraun-links
$bbraun-blue-80: #278eb4;
$bbraun-blue-90: #02708e;
$bbraun-blue-100: #035877;
$bbraun-blue-110: #01526c;
$bbraun-blue-120: #013c50;

$bbraun-light-green-10: #f7f9ea;
$bbraun-light-green-20: #eaf0c3;
$bbraun-light-green-30: #d6e37d;
$bbraun-light-green-40: #bfcf00;
$bbraun-light-green-50: #a2b926;
$bbraun-light-green-60: #87a42f;
$bbraun-light-green-70: #738f32;
$bbraun-light-green-80: #647b31;
$bbraun-light-green-90: #57692e;
$bbraun-light-green-100: #4a592b;
$bbraun-light-green-110: #3e4a26;
$bbraun-light-green-120: #2c331d;

$bbraun-red-10: #fbf5f5;
$bbraun-red-20: #f6e8e8;
$bbraun-red-30: #f2d2d3;
$bbraun-red-40: #f0b6b9;
$bbraun-red-50: #f2949b;
$bbraun-red-60: #f66c7d;
$bbraun-red-70: #f7385f;
$bbraun-red-80: #e40147;
$bbraun-red-90: #ba2749;
$bbraun-red-100: #9c243f;
$bbraun-red-110: #832036;
$bbraun-red-120: #5e1525;

$bbraun-orange-10: #f9f6ef;
$bbraun-orange-20: #f7ead0;
$bbraun-orange-30: #f7d59b;
$bbraun-orange-40: #f7b95a;
$bbraun-orange-50: #f29000;
$bbraun-orange-60: #e18123;
$bbraun-orange-70: #cb6c13;
$bbraun-orange-80: #b15d0e;
$bbraun-orange-90: #994f0a;
$bbraun-orange-100: #824307;
$bbraun-orange-110: #6f3805;
$bbraun-orange-120: #4f2602;

// It's recommended to use tokens variables instead of their shades counterparts
// PRIMARY TOKENS
$bbraun-active: $bbraun-green-60;
$bbraun-accent: $bbraun-purple-90;
$bbraun-background: $bbraun-gray-20;
$bbraun-disabled: $bbraun-gray-50;
$bbraun-clickable: $bbraun-gray-80;
$bbraun-non-clickable: $bbraun-gray-120;
$bbraun-space: #fff;

// SECONDARY TOKENS
$bbraun-links: $bbraun-blue-70;
$bbraun-error: #eb0000;
$bbraun-warning: #fecc00;

// Opacity levels
$bbraun-opacity-hover: 0.16;
$bbraun-opacity-active: 0.24;
$bbraun-opacity-disabled: 0.48;

// Border radius
$bbraun-border-radius: 8px;

// Shadows
$bbraun-shadow-s: 0 2px 4px rgba($bbraun-non-clickable, $bbraun-opacity-hover);
$bbraun-shadow-m: 0 4px 8px rgba($bbraun-non-clickable, $bbraun-opacity-hover);
$bbraun-shadow-l: 0 8px 16px rgba($bbraun-non-clickable, $bbraun-opacity-hover);
$bbraun-shadow-xl: 0 12px 24px
  rgba($bbraun-non-clickable, $bbraun-opacity-hover);
$bbraun-shadow-2xl: 0 24px 48px
  rgba($bbraun-non-clickable, $bbraun-opacity-hover);

// Font
$bbraun-font-family: arial, 'Helvetica Neue', helvetica, sans-serif;
$bbraun-font-weight-light: 300;
$bbraun-font-weight-normal: 400;
$bbraun-font-weight-semi-bold: 600;
$bbraun-font-weight-bold: 700;
$bbraun-font-size-small: 12px;
$bbraun-font-size-medium: 14px;
$bbraun-font-size-big: 16px;
$bbraun-line-height: 1.5;

// Gutters
$bbraun-xl-gutter: 48px;
$bbraun-l-gutter: 32px;
$bbraun-m-gutter: 24px;
$bbraun-s-gutter: 16px;

// Spacing
$bbraun-spacing-2xs: 4px;
$bbraun-spacing-xs: 8px;
$bbraun-spacing-s: 12px;
$bbraun-spacing-sm: 16px;
$bbraun-spacing-m: 24px;
$bbraun-spacing-ml: 32px;
$bbraun-spacing-l: 48px;
$bbraun-spacing-xl: 64px;
$bbraun-spacing-2xl: 96px;
$bbraun-spacing-3xl: 128px;

// Break points
$bbraun-xl-breakpoint: 1920px;
$bbraun-l-breakpoint: 1280px;
$bbraun-m-breakpoint: 600px;
$bbraun-s-breakpoint: 304px;
$breakpoints: (
  s: $bbraun-s-breakpoint,
  m: $bbraun-m-breakpoint,
  l: $bbraun-l-breakpoint,
  xl: $bbraun-xl-breakpoint
);

/// @name Breakpoint
/// Breakpoint getter
/// @param {String} $size: s | m | l | xl
/// @output breakpoint string, ex. '1024px'
@function breakpoint($size) {
  @return map-get($breakpoints, $size);
}

// Variables used internally
$cx-navigation-drawer-width: 256px;
$cx-profile-dropdown-width: 300px;
