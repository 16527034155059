/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */
@use '../../utilities/variables' as *;

// Divider
.mat-divider {
  border-top-color: $bbraun-gray-30;
}

.mat-divider.mat-divider-vertical {
  border-right-color: $bbraun-gray-30;
}
