/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */

.mat-mdc-slider.mdc-slider {
  &--disabled {
    .mdc-slider__track--active_fill {
      border-color: transparent !important;
      background-color: $bbraun-active !important;
    }

    .mdc-slider__thumb-knob {
      background-color: $bbraun-active !important;
    }
  }

  .mdc-slider__track {
    height: 2px;

    .mdc-slider__track--inactive {
      height: 2px;
      background-color: $bbraun-clickable;
      opacity: 1;
    }

    .mdc-slider__track--active {
      top: -1px;
      height: $bbraun-spacing-2xs;
    }

    .mdc-slider__tick-marks {
      .mdc-slider__tick-mark--inactive {
        width: $bbraun-spacing-xs;
        height: $bbraun-spacing-xs;
        opacity: 1;
        background-color: $bbraun-clickable;
      }

      .mdc-slider__tick-mark--active {
        width: $bbraun-spacing-xs;
        height: $bbraun-spacing-xs;
        opacity: 1;
        background-color: $bbraun-active;
      }
    }
  }

  .mdc-slider__thumb.mat-mdc-slider-visual-thumb {
    transform: translateX(6px);

    .mdc-slider__value-indicator-container {
      opacity: 1;
      bottom: 52px;

      .mdc-slider__value-indicator {
        opacity: 1;
        background-color: $bbraun-non-clickable;
        padding: 0 $bbraun-spacing-sm;
        color: $bbraun-space;
        border-radius: $bbraun-border-radius;

        .mdc-slider__value-indicator-text {
          font-family: $bbraun-font-family;
          font-size: $bbraun-font-size-medium;
        }

        &::before {
          display: none;
        }
      }
    }

    .mdc-slider__thumb-knob {
      box-shadow: none;
      border: 1px solid $bbraun-space;
    }

    .mat-ripple-element.mat-mdc-slider-hover-ripple {
      height: $ripple-size;
      width: $ripple-size;
      top: 0;
      left: 0;
      background-color: $bbraun-active;
      opacity: $bbraun-opacity-hover;
    }

    .mat-ripple.mat-mdc-focus-indicator {
      border-radius: 50%;
      margin: auto;
      height: $ripple-size;
      width: $ripple-size;
    }
  }
}
