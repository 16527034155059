/**
 * Defines a custom B.Braun theme data structure composed of the B.Braun colors.
 */
@use '@angular/material' as mat;
@use '../../styles/utilities/variables' as *;

@function bbraun-typography(
  $font-size,
  $line-height: $bbraun-line-height,
  $font-weight: 400
) {
  @return mat.m2-define-typography-level(
    $font-size,
    $line-height,
    $font-weight,
    $bbraun-font-family,
    normal
  );
}

$typography: mat.m2-define-typography-config(
  $headline-1: bbraun-typography(36px, $font-weight: 700),
  $subtitle-2: bbraun-typography($bbraun-font-size-big),
  $subtitle-1: bbraun-typography($bbraun-font-size-big, $font-weight: 500),
  $body-2: bbraun-typography($bbraun-font-size-medium, $font-weight: 500),
  $body-1: bbraun-typography($bbraun-font-size-big),
  $button:
    bbraun-typography($bbraun-font-size-medium, $bbraun-font-size-medium, 500),
  $caption: bbraun-typography($bbraun-font-size-small, 20px)
);

/**
 * Include the common styles for Angular Material and compute the custom BBraun font configuration.
 * Should be included only once otherwise it will duplicate theming and font configurations.
 */
@include mat.elevation-classes();
@include mat.app-background();

@function bbraun-palette($main, $lighter, $darker) {
  $color: (
    main: $main,
    lighter: $lighter,
    darker: $darker,
    // For slide toggle
    300: $main,
    600: $main,
    900: $darker,
    contrast: (
      main: $bbraun-space,
      lighter: $bbraun-non-clickable,
      darker: $bbraun-space,
      300: $bbraun-space,
      600: $bbraun-space,
      900: $bbraun-space
    )
  );

  @return mat.m2-define-palette($color, main, lighter, darker);
}

$theme-primary: bbraun-palette(
  $bbraun-active,
  $bbraun-green-30,
  $bbraun-green-80
);
$theme-accent: bbraun-palette(
  $bbraun-accent,
  $bbraun-purple-60,
  $bbraun-purple-110
);
$theme-warn: bbraun-palette($bbraun-error, $bbraun-red-60, $bbraun-red-100);
$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn
    ),
    typography: $typography
  )
);

// Mixin provided by Angular Material that will output styles for all components in the library.
@include mat.all-component-themes($theme);

// Ripple size of slide toggle
@include mat.slide-toggle-density(-2);
