/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */

$chip-remove-hover-box-position: -4px;
$min-input-height: 44px;

@mixin chip-color($color, $bg-color) {
  background-color: $bg-color;
  border: 1px solid $color;

  &,
  &.mat-mdc-chip-row:not([readonly='true']),
  .mat-mdc-chip-avatar,
  .mat-mdc-chip-remove {
    color: $color;
  }

  .mat-mdc-chip-remove {
    &:hover::after {
      background-color: rgba($color, $bbraun-opacity-hover) !important;
    }

    &:active::after {
      background-color: rgba($color, $bbraun-opacity-active) !important;
    }
  }

  &:not(.mdc-evolution-chip--disabled, :has(.mat-mdc-chip-remove), [readonly]) {
    &:hover .mat-mdc-chip-ripple {
      background-color: rgba($color, $bbraun-opacity-hover) !important;
    }

    &:active .mat-mdc-chip-ripple {
      background-color: rgba($color, $bbraun-opacity-active) !important;
    }
  }
}

.mat-mdc-chip.mat-mdc-standard-chip {
  // Reset material
  &:not(.mdc-evolution-chip--disabled),
  &.mdc-evolution-chip--disabled {
    .mdc-evolution-chip__text-label {
      color: inherit;
    }
  }

  .mat-mdc-chip-avatar {
    height: 24px;
    opacity: 1;
  }

  .mdc-evolution-chip__action--presentational,
  .mdc-evolution-chip__action:not(button.mat-mdc-chip-remove) {
    cursor: inherit;
  }

  &:hover,
  &.cdk-focused {
    .mat-mdc-chip-focus-overlay {
      opacity: 0;
    }
  }

  // Overrides
  .mat-mdc-chip-remove {
    opacity: 1;
    padding: 0 !important;
    margin: 0 8px;

    &::after {
      position: absolute;
      top: $chip-remove-hover-box-position;
      left: $chip-remove-hover-box-position;
      right: $chip-remove-hover-box-position;
      bottom: $chip-remove-hover-box-position;
      content: '';
      border-radius: 50%;
    }
  }

  &.mdc-evolution-chip--disabled,
  &[readonly='true'] {
    .mat-mdc-chip-remove,
    .mat-mdc-chip-remove::after {
      width: 0;
      margin-left: 4px;
    }
  }

  &:not(.mdc-evolution-chip--disabled) {
    @include chip-color($bbraun-clickable, $bbraun-gray-10);

    &.mat-primary[selected],
    &.mat-mdc-chip-selected {
      @include chip-color($bbraun-active, $bbraun-green-10);
    }

    &.mat-warn {
      @include chip-color($bbraun-error, $bbraun-red-10);
    }
  }

  &.mdc-evolution-chip--disabled {
    @include chip-color($bbraun-disabled, $bbraun-gray-10);
    opacity: 1;
  }

  &:not(.mdc-evolution-chip--disabled)[readonly],
  &[readonly='true'] {
    &,
    .mat-mdc-chip-avatar {
      color: $bbraun-non-clickable;
    }
  }
}

.mat-mdc-chip-list-disabled,
.mat-mdc-chip-grid[readonly] {
  &,
  & + span {
    pointer-events: auto;
  }
}

.mat-mdc-chip-listbox {
  .mat-mdc-chip-option {
    cursor: pointer !important;
  }
}

.mat-mdc-chip-listbox[multiple] .mat-mdc-chip-option.mat-mdc-chip-selected {
  .mdc-evolution-chip__checkmark {
    height: 24px;
    width: 24px;
    color: $bbraun-active;
  }
}

.mat-mdc-chip-listbox:not([multiple])
  .mat-mdc-chip-option.mat-mdc-chip-selected {
  .mat-mdc-chip-graphic {
    width: 0;

    .mdc-evolution-chip__checkmark {
      display: none;
    }
  }
}

.mat-form-field-appearance-outline.mat-mdc-form-field-type-mat-chip-grid
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-flex
  .mat-mdc-form-field-infix {
  padding: 0;

  .mat-mdc-chip-grid {
    min-height: $min-input-height;

    .mdc-evolution-chip {
      margin-top: 5px;
      margin-bottom: auto;
    }

    input.mat-mdc-chip-input {
      min-height: $min-input-height;
      flex: 1 1 0 !important;
    }
  }
}
