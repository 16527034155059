/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */

$slide-toggle-border-radius: 10px;
$slide-toggle-track-width: 38px;
$slide-toggle-track-height: 16px;

// Slide toggle
.mat-mdc-slide-toggle.mat-primary {
  margin-top: $bbraun-spacing-2xs;

  .mdc-form-field {
    height: $ripple-size;

    &.mdc-form-field--align-end label {
      padding-right: $bbraun-spacing-s;
    }

    &:not(.mdc-form-field--align-end) label {
      padding-left: $bbraun-spacing-s;
    }
  }

  .mdc-switch {
    width: $slide-toggle-track-width;

    .mdc-switch__ripple {
      z-index: 2;
    }

    .mdc-switch__shadow {
      box-shadow: none !important;
    }

    .mdc-switch__track {
      height: $slide-toggle-track-height;
      border-radius: $slide-toggle-border-radius;
    }

    .mdc-switch__handle::after {
      background: $bbraun-space !important;
    }

    &:disabled {
      .mdc-switch__handle::after {
        opacity: 1;
        border: 1px solid $bbraun-disabled;
      }

      .mdc-switch__handle::after,
      .mdc-switch__track {
        opacity: 1;
      }
    }

    &:enabled:not(:disabled) {
      .mdc-switch__track::before,
      .mdc-switch__handle::before {
        background: $bbraun-clickable !important;
      }
    }

    &.mdc-switch--selected {
      &:enabled .mdc-switch__handle::after {
        border: 1px solid $bbraun-active;
      }

      &:enabled:hover .mdc-switch__ripple {
        &::before,
        &::after {
          background-color: $bbraun-active;
          opacity: $bbraun-opacity-hover;
        }

        .mat-ripple-element {
          background-color: $bbraun-active;
          opacity: $bbraun-opacity-active;
        }
      }

      &:disabled {
        .mdc-switch__track::after,
        .mdc-switch__track::before {
          background: rgba($bbraun-active, $bbraun-opacity-disabled);
        }

        .mdc-switch__handle::after {
          border: 1px solid rgba($bbraun-active, $bbraun-opacity-disabled);
        }
      }
    }

    &.mdc-switch--unselected {
      &:enabled .mdc-switch__handle::after {
        border: 1px solid $bbraun-clickable;
      }

      &:enabled:hover .mdc-switch__ripple {
        &::before,
        &::after {
          background-color: $bbraun-clickable;
          opacity: $bbraun-opacity-hover;
        }

        .mat-ripple-element {
          background-color: $bbraun-clickable;
          opacity: $bbraun-opacity-active;
        }
      }

      &:disabled .mdc-switch__track {
        &::before,
        &::after {
          background: $bbraun-disabled;
        }
      }
    }
  }
}
