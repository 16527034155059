/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */
@use '../../utilities/variables' as *;

// CxNavigationDrawer Component
.cx-navigation-drawer {
  %cx-navigation-button-margin {
    margin: $bbraun-spacing-2xs 0 !important;
  }

  // Remove hover and ripple effect on active button
  %cx-navigation-button-ripple {
    .mat-mdc-button-persistent-ripple.mdc-button__ripple {
      background: transparent !important;
      opacity: 0 !important;
    }

    .mat-ripple-element {
      background: transparent !important;
      opacity: 0 !important;
    }
  }

  .mdc-button,
  .cx-button-with-icon.cx-mat-button-base {
    @extend %cx-navigation-button-margin;

    min-width: 58px !important;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    &.mdc-button[disabled] {
      color: $bbraun-non-clickable;
      opacity: 1;
    }

    &__label {
      display: contents;
    }

    &.cx-navigation-drawer-button-active svg {
      color: $bbraun-active;
    }

    &:not(.cx-navigation-drawer-button-active) svg {
      color: $bbraun-clickable;
    }

    &.cx-navigation-drawer-button-active:hover,
    &.cx-navigation-drawer-button-active:active {
      @extend %cx-navigation-button-ripple;
    }
  }

  .cx-button-with-icon.mdc-button[mat-stroked-button] {
    padding: 10px $bbraun-spacing-sm !important;
  }

  .cx-icon-in-button {
    margin-right: $bbraun-spacing-xs !important;
  }

  mat-expansion-panel.mat-expanded {
    margin: unset !important;
  }

  .mat-expansion-panel-header {
    @extend %cx-navigation-button-margin;

    padding: 10px 10px 10px 0 !important;
    height: 24px !important;

    .mat-expansion-indicator {
      margin-right: 14px;
      transform-origin: center 12px;

      &::after {
        margin-top: -5px;
      }
    }

    &:hover {
      border-radius: $bbraun-border-radius;
      background: rgba($bbraun-clickable, $bbraun-opacity-hover) !important;
    }

    .mdc-button:hover {
      background: transparent !important;
      border: 0 !important;
    }

    .cx-button-with-icon:hover,
    .cx-button-with-icon:active {
      @extend %cx-navigation-button-ripple;
    }
  }

  .mat-expansion-panel {
    box-shadow: none !important;
  }

  .mat-expansion-panel-body {
    padding: 0;

    cx-navigation-drawer-button
      .cx-button-with-icon.mdc-button[mat-stroked-button] {
      padding-left: $bbraun-spacing-xl !important;
    }
  }

  // Overwrite material divider inside navigation drawer
  .mat-divider {
    @extend %cx-navigation-button-margin;
  }
}
