/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */

// Checkbox
$checkbox-input-size: 18px;

.mat-mdc-checkbox.mat-primary {
  margin-top: $bbraun-spacing-2xs;

  .mdc-checkbox__checkmark {
    color: $bbraun-space !important;
  }

  .mdc-checkbox {
    padding: calc(($ripple-size - $checkbox-input-size) / 2);

    .mdc-checkbox__background {
      top: calc(($ripple-size - $checkbox-input-size) / 2);
      left: calc(($ripple-size - $checkbox-input-size) / 2);
    }

    .mdc-checkbox__native-control {
      &:disabled:checked,
      &:disabled:indeterminate {
        & ~ .mdc-checkbox__background {
          background-color: rgba($bbraun-active, $bbraun-opacity-disabled);
        }
      }

      &:disabled:not(:checked, :indeterminate) ~ .mdc-checkbox__background {
        border-color: $bbraun-disabled !important;
      }

      &:enabled:not(:checked, :indeterminate) ~ .mdc-checkbox__background {
        border-color: $bbraun-clickable !important;
      }

      &:indeterminate,
      &.mdc-checkbox--selected {
        & ~ .mat-mdc-checkbox-ripple {
          .mat-ripple-element {
            background-color: rgba($bbraun-active, $bbraun-opacity-active);
          }
        }
      }

      &:not(:indeterminate)
        ~ .mdc-checkbox__background
        .mdc-checkbox__mixedmark {
        border-color: $bbraun-clickable;
      }

      &:indeterminate {
        & ~ .mdc-checkbox__ripple {
          background: $bbraun-active;
        }

        & ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
          width: 50%;
          border-radius: 5px;
          border-color: $bbraun-space;
        }
      }
    }

    &:hover .mdc-checkbox__native-control {
      &:not(:disabled) ~ .mdc-checkbox__ripple {
        opacity: $bbraun-opacity-hover;
      }

      &:not(:disabled, :checked, :indeterminate) ~ .mdc-checkbox__ripple {
        background: $bbraun-clickable;
      }
    }
  }
}

// Remove @supports when Firefox will finally support :has selector
@supports selector(:has( * )) {
  ul:has(.mat-mdc-checkbox) {
    list-style-type: none;
    margin-top: 2px;
    padding: 0;
  }
}

// Remove this code when Firefox will finally support :has selector
@supports not selector(:has( * )) {
  .mat-mdc-checkbox + ul {
    list-style-type: none;
    margin-top: 2px;
    padding: 0;
  }
}
