/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */
@use '../../../styles/base/global-classes' as *;

// Button
mat-icon.cx-icon-in-button {
  margin-left: -2px !important;
  margin-top: -2px !important;
  margin-right: 10px !important;
}

.cx-icon-stroked-button.mdc-button[mat-stroked-button] {
  min-width: 44px;
  padding: 0 !important;

  .mat-icon {
    @extend .small-icon;
    margin: 0;
  }
}

.cx-button-with-icon.cx-mat-button-base {
  line-height: 24px !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.cx-button-with-landscape-icon.cx-mat-button-base {
  line-height: 24px !important;
  padding-top: 10px !important;
  padding-bottom: 9px !important;
}
