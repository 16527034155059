/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */
@use '../../utilities/variables' as *;

// Menu
.mat-mdc-menu-panel {
  border-radius: $bbraun-border-radius !important;
  box-shadow: $bbraun-shadow-xl !important;

  .mat-mdc-menu-content {
    padding: $bbraun-spacing-2xs $bbraun-spacing-xs;

    .mat-mdc-menu-item {
      margin: $bbraun-spacing-2xs 0;
      border-radius: $bbraun-border-radius;

      .mdc-list-item__primary-text,
      .mat-mdc-menu-item-text {
        display: flex;

        // Because of menu-item in the header
        &,
        & .mat-icon {
          color: $bbraun-clickable !important;
          flex-shrink: 0;
        }
      }

      .mat-icon {
        color: $bbraun-clickable;

        &.x-small-icon {
          color: $bbraun-non-clickable !important;
        }
      }

      &:not([disabled]):hover {
        background: rgba($bbraun-clickable, $bbraun-opacity-hover);
      }

      .mat-ripple-element {
        background: $bbraun-clickable;
        opacity: $bbraun-opacity-active;
      }
    }
  }
}
