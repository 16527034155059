/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */
@use '../../utilities/variables' as *;

/* stylelint-disable no-descending-specificity */
@mixin bbraun-base-select-autocomplete-panel() {
  $mat-option-height: 48px;
  $visible-mat-options: 6;

  box-sizing: border-box;
  max-height: $bbraun-spacing-xs * 2 + $mat-option-height * $visible-mat-options !important;
  padding: $bbraun-spacing-2xs $bbraun-spacing-xs !important;

  // Options
  .mat-mdc-option {
    margin: $bbraun-spacing-2xs 0 !important;
    border-radius: $bbraun-border-radius;

    &:not([disabled]) .mdc-list-item__primary-text {
      color: $bbraun-clickable;
    }

    &[disabled] {
      color: $bbraun-disabled !important;

      .mdc-list-item__primary-text,
      .mat-pseudo-checkbox-disabled {
        opacity: 1;
      }
    }

    .mat-mdc-option-ripple {
      display: none;
    }

    .mdc-list-item__primary-text {
      display: flex;
      align-items: center;
    }
  }
}

.mat-calendar {
  font-family: $bbraun-font-family;
}

.mat-mdc-autocomplete-panel,
.mat-datepicker-content,
.mat-mdc-select-panel,
.mdc-menu-surface {
  margin: 4px 0 !important;
  border-radius: $bbraun-border-radius !important;
  box-shadow: $bbraun-shadow-xl !important;
}

.mat-mdc-select-panel {
  margin: 38px 0;

  @include bbraun-base-select-autocomplete-panel;

  // Options
  .mat-mdc-option {
    &:not(.mat-option-disabled) {
      &:hover {
        background-color: rgba($bbraun-gray-80, $bbraun-opacity-hover);
      }

      &:active {
        background-color: rgba($bbraun-gray-80, $bbraun-opacity-active);
      }
    }

    &.mdc-list-item--selected {
      color: $bbraun-active !important;
      background-color: rgba($bbraun-active, 0.08) !important;
    }
  }
}

.mat-mdc-autocomplete-panel {
  @include bbraun-base-select-autocomplete-panel;

  // Options
  .mat-mdc-option {
    .mat-pseudo-checkbox {
      display: none;
    }

    &:not(.mat-mdc-option[disabled]) {
      &:not(:hover) {
        background: transparent;
      }

      &:hover,
      &.mat-mdc-option-active {
        background-color: rgba($bbraun-gray-80, $bbraun-opacity-hover);
      }

      &:active,
      &.mat-active {
        background-color: rgba($bbraun-gray-80, $bbraun-opacity-active);
      }
    }
  }
}
