/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */
@use '../../utilities/variables' as *;

// CxFloatingButton Component
.cx-floating-button-green {
  &.mat-mdc-fab.mat-primary {
    background: $bbraun-active !important;

    & .mat-ripple-element {
      background: $bbraun-non-clickable !important;
      opacity: $bbraun-opacity-active !important;
    }

    &:hover > .mat-mdc-button-persistent-ripple {
      background: $bbraun-non-clickable !important;
      opacity: $bbraun-opacity-hover !important;
    }

    &.cx-floating-button-no-expand:hover > .mat-mdc-button-persistent-ripple {
      background: $bbraun-non-clickable;
      opacity: $bbraun-opacity-hover;
      box-shadow: $bbraun-shadow-l;
    }
  }
}
