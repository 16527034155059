/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */

// CxHeader Component
cx-header {
  .cx-header-search-bar {
    .cx-search-bar {
      border: 1px solid transparent !important;
    }
  }

  .mat-mdc-icon-button {
    &.cx-header-white-icon,
    &.cx-menu-button {
      @extend .cx-icon-button-md;
      @include cx-button($bbraun-clickable, $bbraun-space);
    }
  }

  cx-menu {
    .mat-mdc-icon-button.cx-menu-button {
      mat-icon {
        @extend .white-medium-icon;
      }

      &:hover {
        background: rgba($bbraun-space, $bbraun-opacity-hover) !important;
      }

      &-opened {
        background: rgba($bbraun-space, $bbraun-opacity-active) !important;
      }

      &.mdc-button[disabled]:hover {
        background: transparent !important;
      }
    }
  }
}
