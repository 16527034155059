/**
 * Definitions of internal overrides for Cortex components
 * and global classes for a specific Material or Cortex component.
 * Global classes defined here should have cx- prefix.
 */
//
/** adding css animation due to changes in the angular animation
https://github.com/angular/components/pull/30235 in Angular Material 19.0.5
 */
cx-sidebar-container.mat-drawer-container-has-open {
  cx-sidebar {
    transform: none !important;
  }
}

cx-sidebar {
  transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

cx-sidebar.mat-drawer-opened.mat-drawer-fixed {
  transform: none !important;
}

// CxSidebar Component
.cx-sidebar-portal-container {
  height: 100%;

  & > * {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
