/**
 * All definitions in this file are used to globally redefine styles for Angular Material components
 */

// Regular button
.cx-mat-button-base {
  font-weight: bold;
  height: 44px;
  padding: 14px $bbraun-spacing-m !important;
  font-size: $bbraun-font-size-big;
  line-height: $bbraun-font-size-big !important;
  border-radius: $bbraun-border-radius !important;

  .mdc-button__label::first-letter {
    text-transform: uppercase;
  }
}

@mixin cx-button(
  $color,
  $overlay-bg: $color,
  $ripple-bg: $overlay-bg,
  $border: false,
  $bg: none
) {
  &,
  &:not(.mdc-button[disabled]) {
    // Overriding disabled states from Material withour using !important
    &,
    &:disabled,
    &[disabled][disabled] {
      color: $color;

      @if $bg != none {
        background: $bg;
      }
    }

    @if $border {
      border-color: $color !important;
    }

    @if $overlay-bg != none {
      &:hover > .mat-mdc-button-persistent-ripple, &:active > .mat-mdc-button-persistent-ripple {
        background: $overlay-bg;
        opacity: $bbraun-opacity-hover !important;
      }
    }

    .mat-ripple-element {
      z-index: 2;
      background: $ripple-bg;
      opacity: $bbraun-opacity-active;
    }
  }
}

@mixin cx-button-icon($ripple-square, $icon-size: none) {
  height: $ripple-square;
  width: $ripple-square;

  @if $icon-size != none {
    &,
    .mat-icon {
      font-size: $icon-size;
    }

    .mat-icon {
      height: $icon-size;
      width: $icon-size;
      line-height: $icon-size;
    }
  }
}

.mat-mdc-button.mat-mdc-button-base {
  @extend .cx-mat-button-base;
  @include cx-button($bbraun-clickable);

  &.mat-accent, &.mat-alternative {
    @include cx-button($bbraun-accent);
  }

  // Snackbars actions
  &.mat-info {
    @include cx-button($bbraun-links);
  }

  &.mat-success {
    @include cx-button($bbraun-active);
  }

  &.mat-warning {
    @include cx-button($bbraun-orange-90);
  }

  &.mat-error {
    @include cx-button($bbraun-error);
  }
}

.mdc-button[mat-flat-button] {
  @extend .cx-mat-button-base;
  @include cx-button(
    $bbraun-space,
    $bbraun-non-clickable,
    $bg: $bbraun-clickable
  );

  &.mat-accent {
    @include cx-button(
      $bbraun-space,
      $bbraun-non-clickable,
      $bg: $bbraun-accent
    );
  }

  &.mat-warn {
    @include cx-button(
      $bbraun-space,
      $bbraun-non-clickable,
      $bg: $bbraun-error
    );
  }
}

.mdc-button[mat-stroked-button] {
  @extend .cx-mat-button-base;
  @include cx-button($bbraun-clickable, $border: true);
  padding: 13px $bbraun-spacing-m !important;

  &.mat-accent {
    @include cx-button($bbraun-accent, $border: true);
  }

  &.mat-primary {
    @include cx-button($bbraun-active, $border: true);
  }
}

.mdc-button[disabled],
// Added [disabled] twice in order to override Mat styles, which are built like this for some reason
.mat-mdc-icon-button[disabled][disabled] {
  &,
  &.mat-primary,
  &.mat-accent {
    opacity: $bbraun-opacity-disabled;
  }
}

// Floating button
.mat-mdc-fab {
  &.mat-accent {
    @include cx-button(
      $bbraun-accent,
      none,
      $bbraun-accent,
      $bg: $bbraun-space
    );
  }

  &.mat-primary {
    @include cx-button(
      $bbraun-space,
      none,
      $bbraun-non-clickable,
      $bg: $bbraun-accent
    );
  }
}

.mat-mdc-mini-fab {
  &.mat-accent {
    @include cx-button($bbraun-accent, $bg: $bbraun-space);
  }

  &.mat-primary {
    @include cx-button($bbraun-space, $bbraun-active, $bg: $bbraun-active);
  }
}

.mat-mdc-fab.cx-floating-base-button,
.mat-mdc-mini-fab {
  &.mat-accent,
  &.mat-primary {
    font-weight: bold;

    &,
    &:hover {
      box-shadow: $bbraun-shadow-l;
    }

    &:active {
      box-shadow: $bbraun-shadow-m !important;
    }

    // :not(.mdc-fab--extended) for overriding purposes
    &:not(.mdc-fab--extended),
    & > .mat-mdc-button-persistent-ripple.mdc-fab__ripple {
      border-radius: calc($bbraun-border-radius * 7);
    }
  }
}

// Icon button
.mat-mdc-icon-button {
  @extend .cx-mat-button-base;
  @include cx-button($bbraun-clickable);

  border-radius: 50% !important;

  &:not(.cx-icon-button-md, .cx-icon-button-lg) {
    @include cx-button-icon(36px);
  }

  padding: 0 !important;

  &.mat-accent {
    @include cx-button($bbraun-accent);
  }

  &.mat-primary {
    @include cx-button($bbraun-active);
  }

  &.cx-icon-button-md {
    @include cx-button-icon(40px, 32px);
  }

  &.cx-icon-button-lg {
    @include cx-button-icon(60px, 48px);
  }
}

// Buttons with icons including those in footer
.mat-mdc-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  &:not(.mat-mdc-icon-button) mat-icon {
    height: 24px;
    width: 24px;
    font-size: 24px;
  }
}
