// Import the map module from Sass
@use "sass:map";

// Define a mixin to generate the caret styles for each side of the popover
@mixin generateCaret($side) {
  // Define maps to store the values for each side
  $cx-opposite-side: ("top": bottom, "bottom": top, "left": right, "right": left);
  $cx-position-map: ("top": left, "bottom": left, "left": top, "right": top);
  $cx-rotation-map: ("top": 225deg, "bottom": 45deg, "left": 135deg, "right": -45deg);
  $cx-margin-start-map: ("top": right, "bottom": right, "left": bottom, "right": bottom);
  $cx-margin-end-map: ("top": left, "bottom": left, "left": top, "right": top);

  // Get the values from the maps based on the side
  $margin-to-use: map.get($cx-opposite-side, $side);
  $position: map.get($cx-position-map, $side);
  $rotate-value: map.get($cx-rotation-map, $side);
  $margin-start-side: map.get($cx-margin-start-map, $side);
  $margin-end-side: map.get($cx-margin-end-map, $side);
  $cx-caret-unit: 6px;
  $cx-offset-spacing: -32px;
  $cx-caret-offset: 7.5px;

  @if $side == bottom or $side == right {
    $cx-caret-offset: 7px;
  }

  // Generate the base styles for the caret
  .cx-popover-#{$side} {
    .cx-popover {
      margin-#{$margin-to-use}: 11px;
      &:before {
        #{$side}: calc(100% - $cx-caret-offset);
        transform: rotate($rotate-value);
      }
    }
  }

  // Generate the styles for the caret when it is centered
  .cx-popover-#{$side}-center {
    .cx-popover {
      &:before {
        #{$position}: calc(50% - $cx-caret-unit);
      }
    }
  }

  // Generate the styles for the caret when it is at the start
  .cx-popover-#{$side}-start {
    .cx-popover-body {
      margin-#{$margin-start-side}: $cx-offset-spacing;
    }
    .cx-popover {
      &:before {
        #{$position}: calc(100% - $cx-caret-unit);
      }
    }
  }

  // Generate the styles for the caret when it is at the end
  .cx-popover-#{$side}-end {
    .cx-popover-body {
      margin-#{$margin-end-side}: $cx-offset-spacing;
    }
    .cx-popover {
      &:before {
        #{$position}: -$cx-caret-unit;
      }
    }
  }
}

// Define the base styles for the popover's carret
.cx-popover {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    border-top: 1px solid;
    border-left: 1px solid;
    background-color: white;
  }

  // Override the styles for the accent theme for the button
  &.cx-popover-theme-accent {
    .mdc-button[mat-stroked-button]:not(.mdc-button[disabled]) {
      color: white;
      border: none;
    }
    .mdc-button[mat-flat-button].mat-accent:not(.mdc-button[disabled]) {
      border: 1px solid white;
    }
  }
}

// Include the mixin for each side
@include generateCaret('top');
@include generateCaret('bottom');
@include generateCaret('left');
@include generateCaret('right');
